<template lang="">
<div class="loader-container">
    <div class="loader">
    </div>
    <img class="img" src="@/assets/logo_ucat3.png">
    <div class="message"><h2>{{message}}</h2></div>
</div>
</template>

<script>
export default {
    props: ['message']
}
</script>

<style scoped>
/* Loader */

.loader-container {
    top: 300px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
}

.loader {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: linear-gradient(45deg, transparent, transparent 40%, #0032A0);
    animation: rotate .8s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        filter: contrast(100%);
    }

    40% {
        transform: rotate(200deg);
        filter: contrast(100%);
    }

    100% {
        transform: rotate(360deg);
        filter: contrast(100%);
    }
}

@keyframes logo {
    0% {
        transform: scale(1);
        opacity: 0.4;
        transform: translateY(-42px);
    }

    40% {
        transform: scale(1.009) translateY(-42px);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 0.4;
        transform: translateY(-42px);
    }
}

.loader:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background: #DDDDDD;
    border-radius: 50%;
    z-index: 1000;
}

.loader:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #DDDDDD;
    border-radius: 50%;
    z-index: 1000;
    z-index: 1;
    filter: blur(30px);
}

.img{
    width: 100px;
    height: 110px;
    z-index: 1001;
    transform: translateY(-42px);
    align-self: center;
    justify-self: center;
    position:absolute;
    opacity: 0.6;
    animation: logo .8s linear infinite;
}
</style>