import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Bootstrap } from 'bootstrap'
import 'bootstrap'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import store from './store'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueApexCharts from "vue3-apexcharts";
import VueHtmlToPaper from "./services/html.to.paper";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


library.add(fas, far)

createApp(App)
.use(router)
.use(store)
.use(VueHtmlToPaper)
.use(Bootstrap)
.use(VueApexCharts)
.component("font-awesome-icon", FontAwesomeIcon)
.component('Datepicker', Datepicker)
.component('v-select', vSelect)
.mount('#app')
