<template>
  <div>
    <table class="display" id="table">
      <thead>
        <tr>
          <th v-for="(col, index) in columns" :key="index">{{ col.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(materia, index) in materias" :key="index">
          <td>{{ materia.nombres }}</td>
          <td>{{ materia.apellidos }}</td>
          <td>{{ materia.nombre_materia }}</td>
          <td>{{ materia.year }}</td>
          <td>{{ materia.seccion }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
window.JSZip = require("jszip/dist/jszip.js");
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const langJson = require("../assets/es-ES.json");

const API_URL = process.env.VUE_APP_API_URL;
const axios = require("axios");

export default {
  data() {
    return {
      notShow: true,
      columns: [
        {
          name: "Nombres Profesor",
        },
        {
          name: "Apellidos Profesor",
        },
        {
          name: "Materia",
        },
        {
          name: "Año",
        },
        {
          name: "Sección",
        },
      ],
    };
  },
  props: ["materias"],
  mounted() {
      $("#table").DataTable({
        language: langJson,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "frtipB",
        buttons: [
          {
            extend: "excel",
            text: "Exportar a archivo excel",
            exportOptions: {
              modifier: {
                page: "current",
              },
            },
          },
        ],
      });
  },
  methods: {},
};
</script>

<style>
td {
  color: black;
}
</style>
