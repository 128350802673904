<template>
  <div class="cols">
    <SidePanel />
    <div class="container">
      <div class="small-container">
        <p style="font-size: 20px">
          Hola de nuevo, para cambiar su contraseña ingrese su contraseña, la
          nueva contraseña y oprima enviar.
        </p>
        <div class="logo-bg">
          <p style="font-size: 15px; font-weight: bold">Cambio de contraseña</p>
          <form
            @submit.prevent="cambiarPassword"
            class="flex-col"
            method="post"
          >
            <div class="form-field-cont">
              <font-awesome-icon icon="key" class="form-icon" />
              <input
                class="form-field"
                type="password"
                v-model="usuario.oldPassword"
                placeholder="Contraseña actual"
              />
            </div>
            <div class="form-field-cont">
              <font-awesome-icon icon="key" class="form-icon" />
              <input
                class="form-field"
                type="password"
                v-model="usuario.password"
                placeholder="Nueva contraseña"
              />
            </div>
            <div class="form-field-cont">
              <font-awesome-icon icon="key" class="form-icon" />
              <input
                class="form-field"
                type="password"
                v-model="usuario.passwordConfirm"
                placeholder="Repita la contraseña"
              />
            </div>
            <button :disabled="submit" type="submit" class="btn-blue">
              Cambiar Contraseña
              <font-awesome-icon
                v-if="!submit"
                icon="square-arrow-up-right"
              /><font-awesome-icon
                v-if="submit"
                class="loader"
                icon="spinner"
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import SidePanel from "./SidePanelAdmin.vue";
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;
export default {
  components: { SidePanel },
  data() {
    return {
      usuario: {
        oldPassword: "",
        password: "",
        passwordConfirm: "",
        cedula: "",
      },
      submit: false,
    };
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      if (this.loggedIn) {
        return this.$store.state.auth.usr.persona;
      }
    },
  },
  methods: {
    async cambiarPassword() {
      this.submit = true
      this.usuario.cedula = this.currentUser.cedula;
      if (this.usuario.oldPassword == "") {
        createToast("Debe introducir su contraseña", { type: "danger" });
        this.submit = false
      } else {
        if (this.usuario.password != this.usuario.passwordConfirm) {
          createToast("Las contraseñas no coinciden", { type: "danger" });
          this.submit = false
        } else {
          await axios
            .post(API_URL + "/cambiar-Contrasena", this.usuario)
            .then((response) => {
              this.submit = true;
              if(response.data == 'Contraseña actual incorrecta.') {
                createToast(response.data, { type: "danger" });
                this.submit = false;
              } else if (response.data == "contraseña cambiada") {
                createToast("Contraseña cambiada con éxito.", {
                  type: "success",
                });
                this.$router.push("/");
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
  },
};
</script>
<style scoped>
.loader {
  animation: rotate 0.8s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    filter: contrast(100%);
  }

  40% {
    transform: rotate(200deg);
    filter: contrast(100%);
  }

  100% {
    transform: rotate(360deg);
    filter: contrast(100%);
  }
}
</style>