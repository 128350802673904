<template>
  <div class="cols">
    <SidePanel />

    <div class="container">
      <div class="container" style="padding-top: 0">
        <h3>
          En esta página podrá visualizar todos los cuestionarios (instrumentos
          de evaluación) creados.
        </h3>
        <div class="small-container">
          <h3>Buscar por carrera o mención, año y sección</h3>
          <div class="form-container-col">
            <label>Seleccione una mención o carrera</label>
            <select
              class="form-field-alt"
              v-model="mencion_id"
              @change="getYear(mencion_id)"
            >
              <option
                v-for="(mencion, index) in menciones"
                :key="index"
                :value="mencion.id"
              >
                {{ mencion.nombre_mencion }}
              </option>
            </select>
            <label>Seleccione el año</label>
            <select
              class="form-field-alt"
              v-model="year"
              @change="getSecciones(mencion_id, year)"
            >
              <option
                v-for="(year, index) in añosMencion"
                :key="index"
                :value="year.year"
              >
                {{ year.year }}
              </option>
            </select>
            <label>Seleccione la sección</label>
            <select class="form-field-alt" v-model="seccion">
              <option
                v-for="(seccion, index) in secciones"
                :key="index"
                :value="seccion.seccion"
              >
                {{ seccion.seccion }}
              </option>
            </select>
            <button
              class="btn-success"
              @click="getEncuestas(mencion_id, year, seccion)"
            >
              Buscar
            </button>
          </div>
        </div>

        <div
          v-if="encuestas"
          style="margin-block: 20px"
          class="small-container"
        >
          <div class="details-container">
            <h3 style="flex-basis: 100%">Información del cuestionario</h3>
            <div
              class="card-submenu"
              style="height: 40px; width: auto; padding-inline: 7px"
            >
              Título: {{ info.titulo }}
            </div>
            <div class="card-submenu" style="height: 40px">
              Fecha creado: {{ info.fecha_creado }}
            </div>
            <div class="card-submenu" style="height: 40px">
              Fecha límite: {{ info.fecha_limite }}
            </div>
            <div class="card-submenu" style="height: 40px">
              Periodo académico: {{ info.periodo_academico }}
            </div>
            <div
              class="card-submenu"
              style="height: 40px"
              v-if="info.es_plantilla"
            >
              Disponible como plantilla: Sí
            </div>
            <div v-if="info.es_plantilla">
            </div>
              <button class="btn" @click="mostrarPlantilla = !mostrarPlantilla">Ver Plantilla</button>
            <div
              class="card-submenu"
              style="height: 40px"
              v-if="!info.es_plantilla"
            >
              Disponible como plantilla: No
            </div>
          </div>
        </div>

        <div v-if="encuestas" v-show="mostrarPlantilla" class="small-container">
          <div class="details-container" style="justify-content:center;">
            <h3 style="flex-basis: 100%">
              Categorías y preguntas del cuestionario
            </h3>
            <div
              class="formulario-responder"
              style="margin: 0;"
              v-for="(categoria, index) in info.categorias"
              :key="index"
            >
              <p class="section">Categoría: {{ categoria.cat }}</p>
              <h4>Preguntas</h4>
              <div
                class="pregunta"
                v-for="(pregunta, index) in categoria.preguntas"
                :key="index"
              >
                <p>{{ index + 1 }}. {{ pregunta.titulo }}</p>
              </div>
            </div>
          </div>
        </div>

        <h3 v-if="encuestas">Tabla de docentes a evaluar</h3>
        <DTEncuestasDisp v-if="encuestas" :datos="encuestas" />

        <h4 v-if="!encuestas">
          No hay cuestionarios creados para la selección actual
        </h4>
        <div v-if="encuestas && !allContainsStudents" class="warning-msg">
          <h4>
            Advertencia: hay cuestionarios en esta lista que no tienen ningun
            estudiante asignado
          </h4>
          <button class="btn-success" @click="assignStudents(mencion_id, year)">
            Asignar Estudiantes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import DTEncuestasDisp from "./DTEncuestasDisp.vue";
import SidePanel from "./SidePanelAdmin.vue";
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      mencion_id: "",
      year: "",
      seccion: "",
      secciones: [],
      menciones: [],
      añosMencion: [],
      encuestas: null,
      info: "",
      mostrarPlantilla: false,
    };
  },
  components: {
    SidePanel,
    DTEncuestasDisp,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    allContainsStudents() {
      return this.encuestas.every(this.checkStudents);
    },
  },
  created() {
    if (
      this.loggedIn &&
      this.$store.state.auth.usr.persona.tipo === "Administrador"
    ) {
      this.getMenciones();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    checkStudents(encuesta) {
      return encuesta.estudiantesAsignados > 0;
    },
    async getMenciones() {
      await axios
        .get(API_URL + "/menciones")
        .then((response) => {
          this.menciones = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getYear(mencion_id) {
      await axios
        .get(API_URL + "/mencion/" + mencion_id + "/años")
        .then((response) => {
          this.añosMencion = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para obtener todas las materias de la mencion seleccionada y del año con sus profesores
    async getMateriasMencionSeccion(mencion_id, year, seccion) {
      this.materias = null;
      await axios
        .get(
          API_URL +
            "/mencion/" +
            mencion_id +
            "/año/" +
            year +
            "/seccion/" +
            seccion +
            "/materias"
        )
        .then((response) => {
          this.materias = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para obtener las secciones
    async getSecciones(mencion_id, year) {
      await axios
        .get(API_URL + "/mencion/" + mencion_id + "/año/" + year + "/secciones")
        .then((response) => {
          this.secciones = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getEncuestas(mencion_id, year, seccion) {
      this.encuestas = null;
      await axios
        .get(
          API_URL +
            "/mencion/" +
            mencion_id +
            "/year/" +
            year +
            "/seccion/" +
            seccion +
            "/encuestaslist"
        )
        .then((res) => {
          if (res.data.info) {
            this.encuestas = res.data.encuestas;
            this.info = res.data.info;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async assignStudents(mencion_id, year) {
      await axios
        .post(API_URL + "/assign-students", {
          mencion_id,
          year,
        })
        .then((res) => {
          createToast(res.data, {
            type: "success",
          });
        })
        .catch((e) => {
          createToast("Error de comunicacion.", {
            type: "warning",
          });
        });
    },
  },
};
</script>

<style>
.warning-msg {
  margin: 10px;
  padding: 20px;
  color: #fff;
  background-color: #f85224;
  border-radius: 15px;
}

.scrollable {
  width: 700px;
  margin-bottom: 70px;
}
</style>
