<template>
  <div
    id="app"
    style="
      height: 200px;
      width: 560px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    "
  >
    <!-- <v-grid
      theme="default"
      readonly="true"
      exporting="true"
      autoSizeColumn="true"
      :source="valores"
      :columns="columns"
    ></v-grid> -->
    <table>
      <tr>
        <th v-for="(col, index) in categorias" :colspan="col.preguntas.length" :key="index">{{ col.categoria }}</th>
      </tr>
      <tr>
        <th v-for="(preg, pregI) in categorias.preguntas"></th>
      </tr>
      <tr v-for="(resp, ind) in valores" :key="ind">
        <td v-for="(val, i) in resp" :key="i">{{ val }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import VGrid from "@revolist/vue3-datagrid";
export default {
  data() {
    return {
      columns: [],
      valores: [],
    };
  },
  props: ["categorias"],
  components: {
    VGrid,
  },
  created() {
    console.log(this.categorias)
  },
};
</script>

<style></style>
