<template>
<div v-if="!load" class="cols">
    <SidePanel />
    <div class="container">
        <h2>Cuestionarios</h2>
        <h4>En este apartado podrá crear cuestionarios y visualizar los cuestionarios previamente creados</h4>
        <div class="cards">
            <router-link class="card" to="/crear-cuestionario">
                <font-awesome-icon style="font-size: 50px; margin-bottom: 10px;" :icon="['fas','square-plus']" class="fa" />
                <div class="small">Crear Cuestionario</div>
            </router-link>
            <router-link class="card" to="/cuestionarios-disp">
                <font-awesome-icon style="font-size: 50px; margin-bottom: 10px;" :icon="['fas','folder-open']" class="fa" />
                <div class="small">Cuestionarios Disponibles</div>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
import SidePanel from '../components/SidePanelAdmin.vue'
export default {
    components: {
        SidePanel
    },
    data() {
        return {

        }
    },
}
</script>
