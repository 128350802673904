<template>
  <div class="container">
    <div class="small-container">
      <p style="font-size: 20px">
        Para recuperar su contraseña ingrese su número de cédula y correo
        electrónico, y oprima el botón enviar, en unos momentos le llegará un
        mensaje con los pasos a seguir.
      </p>
      <div class="logo-bg">
        <p style="font-size: 15px; font-weight: bold">Recuperar contraseña</p>
        <form @submit.prevent="resetPassword" class="flex-col" method="post">
          <div class="input-row">
            <!-- <div class="form-field-cont">
              <select
                class="form-field form-ssm"
                @change="handleChange"
                v-model="usuario.nacionalidad"
              >
                <option value="" selected data-foo="V">V</option>
                <option value="" data-foo="E">E</option>
              </select>
            </div> -->
            <div class="form-field-cont">
              <font-awesome-icon icon="id-card" class="form-icon" />
              <input
                class="form-field"
                type="text"
                v-model="usuario.cedula"
                @keyup="clear()"
                placeholder="Numero de cedula"
              />
            </div>
          </div>

          <div class="form-field-cont">
            <font-awesome-icon icon="at" class="form-icon" />
            <input
              class="form-field"
              type="text"
              v-model="usuario.email"
              placeholder="Correo electronico"
            />
          </div>
          <button :disabled="submit" type="submit" class="btn-blue">
            Enviar
            <font-awesome-icon
              v-if="!submit"
              icon="square-arrow-up-right"
            /><font-awesome-icon v-if="submit" class="loader" icon="spinner" />
          </button>
        </form>
      </div>
      <router-link to="/" class="btn" style="margin-block: 20px"
        ><h5>Volver a Inicio</h5></router-link
      >
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      usuario: {
        cedula: "",
        nacionalidad: "",
        email: "",
      },
      submit: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$store.state.auth.usr = JSON.parse(sessionStorage.getItem("user"));
      this.checkUserType();
    }
  },
  methods: {
    userEmpty() {
      if (this.usuario.cedula == "") {
        return true;
      } else {
        return false;
      }
    },
    clear() {
      this.usuario.cedula = this.usuario.cedula.replace(/[^0-\s\d][\\\^]?/g, '')     
    },
    emailEmpty() {
      if (this.usuario.email == "") {
        return true;
      } else {
        return false;
      }
    },
    handleChange(e) {
      if (e.target.options.selectedIndex > -1) {
        console.log(
          e.target.options[e.target.options.selectedIndex].dataset.foo
        );
      }
    },
    checkUserType() {
      if (this.loggedIn) {
        if (this.$store.state.auth.usr.persona.tipo === "Administrador") {
          this.$router.push("/panel-administrador");
        } else if (this.$store.state.auth.usr.persona.tipo === "Estudiante") {
          this.$router.push("/panel-estudiante");
        }
      }
    },
    async resetPassword() {
      if (!this.userEmpty() && !this.emailEmpty()) {
        this.submit = true;
        await axios
          .post(API_URL + "/auth/recover", this.usuario)
          .then((response) => {
            if (
              response.data == "No se encontro su cedula en nuestro sistema."
            ) {
              createToast(response.data, { type: "danger" });
              this.submit = false;
            }
            if (response.data == "correo enviado") {
              createToast("Fue enviado un enlace a su correo electrónico.", {
                type: "success",
              });
              this.$router.push("/");
            } else if (response.data == "Correo electronico incorrecto.") {
              createToast("Correo electronico incorrecto.", { type: "danger" });
              this.submit = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (this.userEmpty()) {
        createToast("Ingrese su número de cedula.", { type: "danger" });
        this.submit = false;
      } else if (this.emailEmpty()) {
        createToast("Ingrese su correo electronico.", { type: "danger" });
        this.submit = false;
      } else {
        createToast("Debe llenar todos los campos", { type: "danger" });
        this.submit = false;
      }
    },
  },
};
</script>

<style scoped>
.loader {
  animation: rotate 0.8s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    filter: contrast(100%);
  }

  40% {
    transform: rotate(200deg);
    filter: contrast(100%);
  }

  100% {
    transform: rotate(360deg);
    filter: contrast(100%);
  }
}
</style>
