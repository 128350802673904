<template>
  <div class="cols">
    <SidePanel />
    <div class="container">
      <div class="rounded-edges">
        <h5 class="card" style="flex-basis: 100%">
          Bienvenido/a {{ currentUser.apellidos.split(" ")[0] }}
          {{ currentUser.nombres.split(" ")[0] }}, este es el panel de
          Estudiante.
        </h5>
        <h5 class="card">Facultad: {{currentUser.info.facultad}}</h5>
        <h5 class="card">Carrera: {{currentUser.info.nombre_mencion}}</h5>
        <h5 class="card">Año: {{currentUser.info.year}}</h5>
        <h5 class="card">Sección: {{currentUser.info.seccion}}</h5>
        <h5 class="card">Periodo Academico: {{currentUser.info.periodo_academico}}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../components/SidePanelEstudiante.vue";

export default {
  components: {
    SidePanel,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.usr.persona;
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>

<style scoped>
</style>
