<template>
<div v-if="!load" class="cols">
    <SidePanel />
    <div class="container">
        <h2>Configuraciones</h2>
        <h4>En este apartado podrá cargar los datos de docentes, materias, menciones y estudiantes.</h4>
        <div class="cards">
            <router-link class="card" to="/cargar-docentes">
                <font-awesome-icon style="font-size: 50px; margin-bottom: 10px;" :icon="['fas','upload']" class="fa" />
                <div class="small">Cargar Archivos Docentes</div>
            </router-link>
            <router-link class="card" to="/cargar-estudiantes">
                <font-awesome-icon style="font-size: 50px; margin-bottom: 10px;" :icon="['fas','upload']" class="fa" />
                <div class="small">Cargar Archivos Estudiantes</div>
            </router-link>
            <router-link class="card" to="/crear-administrador">
                <font-awesome-icon style="font-size: 50px; margin-bottom: 10px;" :icon="['fas','user-gear']" class="fa" />
                <div class="small">Administradores</div>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
import SidePanel from '../components/SidePanelAdmin.vue'
export default {
    components: {
        SidePanel
    },
    data() {
        return {

        }
    },
}
</script>
