<template>
<div class="example">
    <apexcharts width="500" height="auto" type="line" :options="chartOptions" :series="series"></apexcharts>
</div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    name: "Chart",
    components: {
        apexcharts: VueApexCharts,
    },
    props: ["promedios", 'titulo'],
    data: function () {
        return {
            chartOptions: {
                chart: {
                    id: "basic-line",

                    defaultLocale: "es",
                    locales: [{
                        name: "es",
                        options: {
                            months: [
                                "Enero",
                                "Febrero",
                                "Marzo",
                                "Abril",
                                "Mayo",
                                "Junio",
                                "Julio",
                                "Agosto",
                                "Septiembre",
                                "Octubre",
                                "Noviembre",
                                "Diciembre",
                            ],
                            shortMonths: [
                                "Ene",
                                "Feb",
                                "Mar",
                                "Abr",
                                "May",
                                "Jun",
                                "Jul",
                                "Ago",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dic",
                            ],
                            days: [
                                "Domingo",
                                "Lunes",
                                "Martes",
                                "Miercoles",
                                "Jueves",
                                "Viernes",
                                "Sabado",
                            ],
                            shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                            toolbar: {
                                exportToSVG: "Descargar SVG",
                                exportToPNG: "Descargar PNG",
                                exportToCSV: "Descargar CSV",
                                download: "Descargar",
                                selection: "Selección",
                                selectionZoom: "Selección Zoom",
                                zoomIn: "Acercar",
                                zoomOut: "Alejar",
                                pan: "Mover",
                                reset: "Reiniciar Zoom",
                            },
                        },
                    }, ],
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Preguntas',
                        rotate: 0,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-yaxis-title",
                        },
                    },
                    tickPlacement: 'on',
                    labels: {
                        hideOverlappingLabels: true,
                        trim: true,
                    }
                },
                yaxis: {
                    min: 1,
                    max: 5,
                    title: {
                        text: 'Ponderación',
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-yaxis-title",
                        },
                    },
                },
                title: {
                    text: undefined,
                    align: 'center',
                    margin: 50,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                }
            },
            series: [{
                name: "Promedio",
                data: [],
            }, ],
        };
    },
    created() {
        this.chartOptions.title.text = this.titulo
        let prop = "seccion";
        for (prop in this.promedios) {
            if (this.promedios.hasOwnProperty(prop)) {
                for (var preg in this.promedios[prop].promedios) {
                    if (this.promedios[prop].promedios.hasOwnProperty(preg)) {
                        this.chartOptions.xaxis.categories.push(
                            this.promedios[prop].promedios[preg].pregunta
                        );
                        this.series[0].data.push(
                            this.promedios[prop].promedios[preg].valor
                        );
                    }
                }
            }
        }
    },
    methods: {
        assignValues() {},
    },
};
</script>
