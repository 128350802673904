<template>
  <div class="fade-bg">
    <div class="confirm">
      <div>
        <p class="msg">{{ message }}</p>
      </div>
      <div class="buttons-row">
        <button @click="$emit('confirm')" class="btn-success sm">Aceptar</button>
        <button @click="$emit('cancel')" class="btn-danger sm">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["message"]
};
</script>

<style>
.fade-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 60, 0.364);
}
.confirm {
  margin-inline: auto;
  margin-top: 130px;
  width: 300px;
  padding-inline: 40px;
  padding-block: 20px;
  border-radius: 25px;
  background: white;
}
.msg {
  font-size: 15px;
  color: #000;
}
</style>
