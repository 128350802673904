<template>
  <div class="container">
    <div class="small-container">
      <p style="font-size: 20px">
        Hola de nuevo, para cambiar su contraseña ingrese la nueva contraseña y oprima enviar.
      </p>
      <div class="logo-bg">
        <p style="font-size: 15px; font-weight: bold">Recuperación de contraseña</p>
        <form @submit.prevent="cambiarPassword" class="flex-col" method="post">
          <div class="form-field-cont">
            <font-awesome-icon icon="key" class="form-icon" />
            <input
              class="form-field"
              type="password"
              v-model="usuario.password"
              placeholder="Nueva contraseña"
            />
          </div>
          <div class="form-field-cont">
            <font-awesome-icon icon="key" class="form-icon" />
            <input
              class="form-field"
              type="password"
              v-model="usuario.passwordConfirm"
              placeholder="Repita la contraseña"
            />
          </div>
          <button :disabled="submit" type="submit" class="btn-blue">
            Cambiar Contraseña
            <font-awesome-icon
              v-if="!submit"
              icon="square-arrow-up-right"
            /><font-awesome-icon v-if="submit" class="loader" icon="spinner" />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
        usuario: {
            password: '',
            passwordConfirm: ''
        },
        submit: false,
    };
  },
  created() {
    this.verificarToken()
  },
  methods: {
      async cambiarPassword() {
          if(this.usuario.password != this.usuario.passwordConfirm) {
              createToast('Las contraseñas no coinciden', { type: 'danger'})
          } else {
              await axios.post(API_URL + '/cambiarContrasena', this.usuario).then((response) => {
                  this.submit = true
                  if(response.status = 200) {
                      createToast('Contraseña cambiada con éxito.', { type: 'success' })
                      this.$router.push('/');
                  }
              }).catch((e) => {
                  console.log(e)
              })
          }
      },
      async verificarToken() {
          await axios.get(API_URL + '/verifyToken/' + this.$route.params.token)
          .then((response) =>{
              if(response.status == 501) {
                  createToast('Enlace invalido o vencido.', { type: 'danger' });
                  this.$router.push('/');
              } else {
                  this.usuario = response.data
              }
          }).catch((e) => {
              console.log(e)
          })
      }
  }
};
</script>
<style scoped>
.loader {
  animation: rotate 0.8s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    filter: contrast(100%);
  }

  40% {
    transform: rotate(200deg);
    filter: contrast(100%);
  }

  100% {
    transform: rotate(360deg);
    filter: contrast(100%);
  }
}
</style>