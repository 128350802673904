<template lang="">
<div class=cols>

<SidePanel />
<div class="container" style="padding-top: 0px">
    <div class="container">    
        <h3>Bienvenido/a {{currentUser.apellidos.split(" ")[0]}} {{currentUser.nombres.split(" ")[0]}}, estos son los cuestionarios que usted puede contestar.</h3>
        <div class="tarjetas-encuestas">
            <h3 v-if="encuestas.length < 1">Aún no tiene cuestionarios por responder.</h3>
            <div class="tarjeta-encuesta" v-if="encuestas.length > 0" v-for="(encuesta, index) in encuestas" :key="index">
                <div class="text">
                    <p style="font-size: 13px">
                        Cuestionario para promediar el desempeño del profesor/a {{encuesta.apellidos}} {{encuesta.nombres}}
                    </p>
                    <h6>Materia: {{encuesta.nombre_materia}}</h6>

                </div>
                <div class="bottom-text">
                    <button class="btn" @click="RedirectToSurvey(encuesta.cuestionario_estudiante_id)">Contestar</button>
                    <div>
                        <p>Fecha enviado: {{encuesta.fecha_enviado}}</p>
                        <p>Fecha límite: {{encuesta.fecha_limite}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import SidePanel from '../components/SidePanelEstudiante.vue'
const axios = require('axios')
const API_URL = process.env.VUE_APP_API_URL

export default {
    components: {
        SidePanel
    },
    data() {
        return {
            encuestas: [],
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        currentUser() {
            return this.$store.state.auth.usr.persona;
        },
    },
    created() {
        if (!this.loggedIn) {
            this.$router.push({ name: 'Home'})
        }
        this.getEncuestas()
    },
    methods: {
        RedirectToSurvey(id){
            this.$router.push({path: '/cuestionario/'+ id + '/responder'});
        },
        async getEncuestas() {
            await axios.get(API_URL + '/estudiantes/' + this.currentUser.cedula + '/cuestionarios')
            .then((response) => {
                this.encuestas = response.data
            })
            .catch((e) => {console.log(e)})
        }
    }
}
</script>

<style scoped>
.bottom-text {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}
p{
    font-size: 11px;
    font-weight: bold;
}
</style>
