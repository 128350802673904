<template>
  <Loading v-show="loading" />
  <div v-show="!loading" class="tblcont" style="width: auto; margin-top: 5px">
    <table class="cell-border stripe" id="table">
      <thead>
        <tr>
          <th>Nombres</th>
          <th>Apellidos</th>
          <th>Cédula</th>
          <th>Estado</th>
          <th>Activar / Desactivar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(usuario, index) in datos" :key="index">
          <td>{{ usuario.nombres }}</td>
          <td>{{ usuario.apellidos }}</td>
          <td>{{ usuario.cedula }}</td>
          <td>{{ valorEstado(usuario.status) }}</td>
          <td><button @click="activar(usuario.cedula)">Activar</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
window.JSZip = require("jszip/dist/jszip.js");
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import Loading from "../components/Loading.vue";
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;

const langJson = require("../assets/es-ES.json");

export default {
  components: { Loading },
  emits: ["reload"], 
  data() {
    return {
      loading: true,
    };
  },
  props: ["datos", "colGroup", "colSubGroup"],
  mounted() {
    this.loading = true;
    setTimeout(this.dataTable, 2200);
  },
  methods: {
    valorEstado(valor) {
      if (valor) {
        return "Activo";
      } else {
        return "Inactivo";
      }
    },
    async activar(cedula) {
      await axios
        .post(API_URL + "/enable-disable-admin", {'cedula': cedula})
        .then((res) => {
          if(res.data == "success") {          
            this.$emit('reload');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    dataTable() {
      this.loading = false;
      $("#table").DataTable({
        language: langJson,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "frtipB",
        buttons: [
          {
            extend: "excel",
            text: "Exportar a archivo excel",
            exportOptions: {
              modifier: {
                page: "current",
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.loader-container {
  top: 300px;
  width: 567px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}
td {
  color: black;
}
.tblcont {
  background-color: aliceblue;
  width: 1000px;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 200px;
  overflow-x: scroll;
}
.buttons-excel {
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
  background: linear-gradient(#0032a0, #003fc7);
  color: #fff;
  text-decoration: none !important;
  cursor: pointer;
  margin-top: 50px;
  border: none;
}
.buttons-excel:hover {
  background: linear-gradient(#0040c9, #0953f2);
}
</style>
