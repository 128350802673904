import { createRouter, createWebHistory } from 'vue-router'
import CreatePollVue from '../components/CreatePoll.vue'
import EncuestasEstudianteVue from '../components/EncuestasEstudiante.vue'
import EstadisticasVue from '../components/Estadisticas.vue'
import ResponderEncuestaVue from '../components/ResponderEncuesta.vue'
import UploadProfesoresVue from '../components/UploadProfesores.vue'
import UploadEstudiantesVue from '../components/UploadEstudiantes.vue'
import CambiarContrasenaVue from '../components/CambiarContrasena.vue'
import ConfiguracionVue from '../views/Configuracion.vue'
import CambioDeContrasenaVue from '../components/CambioDeContrasena.vue'
import CrearAdminVue from '../views/CrearAdmin.vue'
import Home from '../views/Home.vue'
import EstMenuVue from '../views/EstMenu.vue'
import CuestionariosVue from '../views/Cuestionarios.vue'
import RecuperarVue from '../views/Recuperar.vue'
import PerfilAdminVue from '../views/PerfilAdmin.vue'
import PerfilEstudianteVue from '../views/PerfilEstudiante.vue'
import EncuestasDisponiblesVue from '../components/EncuestasDisponibles.vue'
import EstadisticasEncuestaVue from '../components/EstadisticasEncuesta.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: "UCAT - Evaluacion Desempeño - Inicio"
        }
    },
    {
        path: '/cuestionarios-disp',
        name: 'Cuestionarios Disponibles',
        component: EncuestasDisponiblesVue,
        meta: {
            title: "Cuestionarios Disponibles - Administrador"
        }
    },
    {
        path: '/configuracion',
        name: 'configuracion',
        component: ConfiguracionVue,
        meta: {
            title: "Configuraciones"
        }
    },
    {
        path: '/crear-cuestionario',
        name: 'Crear cuestionario',
        component: CreatePollVue,
        meta: {
            title: "Crear Cuestionario"
        }
    },
    {
        path: '/cuestionarios-estudiante',
        name: 'Cuestionarios Estudiante',
        component: EncuestasEstudianteVue,
        meta: {
            title: "Cuestionarios por Responder"
        }
    },
    {
        path: '/cuestionario/:id/responder',
        name: 'Responder Cuestionario',
        component: ResponderEncuestaVue,
        params: true,
        meta: {
            title: "Responder Cuestionario"
        }
    },
    {
        path: '/estadisticas',
        name: 'Estadisticas',
        component: EstadisticasVue,
        meta: {
            title: "Estadísticas"
        }
    },
    {
        path: '/estadisticas-generales',
        name: 'Estadisticas Generales',
        component: EstadisticasEncuestaVue,
        meta: {
            title: "Estadísticas Generales"
        }
    },
    {
        path: '/estadisticas-menu',
        name: 'Menu Estadisticas',
        component: EstMenuVue,
        meta: {
            title: "Menú Estadísticas"
        }
    },
    {
        path: '/panel-administrador',
        name: 'Panel Administrador',
        component: PerfilAdminVue,
        meta: {
            title: "Panel Administrador"
        }
    },
    {
        path: '/panel-estudiante',
        name: 'Panel Estudiante',
        component: PerfilEstudianteVue,
        meta: {
            title: "Panel Estudiante"
        }
    },
    {
        path: '/cargar-docentes',
        name: 'Cargar Datos Docentes',
        component: UploadProfesoresVue,
        meta: {
            title: "Cargar Datos Docentes"
        }
    },
    {
        path: '/cargar-estudiantes',
        name: 'Cargar Datos Estudiantes',
        component: UploadEstudiantesVue,
        meta: {
            title: "Cargar Datos Estudiantes"
        }
    },
    {
        path: '/recuperar-contrasena',
        name: 'Recuperar Contraseña',
        component: RecuperarVue,
        meta: {
            title: 'Recuperación de contraseña'
        }
    },
    {
        path: '/cambiar-contrasena/:token',
        name: 'Cambiar Contraseña',
        params: true,
        component: CambiarContrasenaVue,
        meta: {
            title: 'Cambio de contraseña'
        }
    },
    {
        path: '/cambio-contrasena',
        name: 'Cambio Contraseña',
        component: CambioDeContrasenaVue,
        meta: {
            title: 'Cambiar Contraseña'
        }
    },
    {
        path: '/crear-administrador',
        name: 'Crear Cuenta Administrador',
        component: CrearAdminVue,
        meta: {
            title: 'Crear Cuenta Administrador'
        }
    },
    {
        path: '/cuestionarios',
        name: 'Cuestionarios',
        component: CuestionariosVue,
        meta: {
            title: 'Cuestionarios'
        }
    },


]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
});

export default router