<template>
<Loading v-if="load" :message="'Espere se estan enviando sus respuestas.'" />
<div v-if="!load" class="cols">
    <SidePanel />

    <div class="container">
        <div class="title">
            <h3>
                Cuestionario para promediar el desempeño del profesor/a {{cuestionario.apellidos}} {{cuestionario.nombres}}
            </h3>
            <h3>
                Materia: {{cuestionario.nombre_materia}}
            </h3>
        </div>
        <div class="formulario-responder" v-for="(categoria, index) in cuestionario.categorias" :key="index">
            <p class="section">Categoría: {{index + 1}}</p>
            <h4>Preguntas</h4>
            <div class="pregunta" v-for="(pregunta, index) in categoria.preguntas" :key="index">
                <p>{{index + 1}}. {{pregunta.titulo}}</p>
                <h5>Respuestas</h5>
                <div class="respuestas-cont">
                    <div v-for="(respuesta, index) in pregunta.respuestas" :key="index" class="respuestas">
                        <div class="respuesta">{{respuesta.respuesta}}</div>
                        <div :class="{valor: !respuesta.pressed, 'valor-selected': respuesta.pressed}" @click="setPressed(respuesta, pregunta.respuestas)">
                            {{respuesta.valor}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <button class="btn-success" @click="checkAndPost" style="margin-bottom: 30px">Enviar Respuestas</button>
    </div>
</div>
</template>

<script>
const axios = require('axios')
const API_URL = process.env.VUE_APP_API_URL
import SidePanel from '../components/SidePanelEstudiante.vue'
import Loading from './Loading.vue'
import {
    createToast
} from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

export default {
    components: {
        SidePanel,
        Loading
    },

    data() {
        return {
            cuestionarioId: '',
            cuestionario: [
                0,
            ],
            errors: [],
            messages: [],
            respuestasEstudiante: {
                cuestionario: [],
                estudiante: ''
            },
            load: false
        }
    },
    methods: {
        async Getcuestionario() {
            await axios.get(API_URL + '/cuestionario/' + this.cuestionarioId)
            .then((response) => {             
                this.cuestionario = response.data
                this.respuestasEstudiante.cuestionario = response.data
            }).catch((e) => {
                console.log(e)
            })
        },
        setPressed(respuesta, respuestas) {
            respuestas.forEach(resp => {
                resp.pressed = false
            });
            respuesta.pressed = true;
        },
        //funcion para enviar las respuestas
        async checkAndPost() {
            //antes de enviar cualquier solicitud validamos si estan todas las preguntas contestadas
            console.log(this.respuestasEstudiante)
            await this.checkErrors()
            if (this.errors.length == 0) {
                this.load = true
                this.respuestasEstudiante.cuestionario = this.cuestionario;
                //quitamos los datos innecesarios antes de enviar a el servidor
                await this.cleanRequest();
                await axios.post(API_URL + '/cuestionario/guardar-respuestas', this.respuestasEstudiante).then((response) => {
                    if(response.data == "Respuestas Guardadas con Exito!"){
                        createToast(response.data, {type: 'success', onClose: this.$router.push({ name: 'Cuestionarios Estudiante'})})
                    } 
                    else if(response.data == 'Usted no puede responder a esta cuestionario.') {
                        createToast(response.data, { type: 'danger'})
                        this.$router.push({ name: 'Cuestionarios Estudiante' })
                    }
                }).catch((e) => {
                    createToast('Ha ocurrido un error con el servidor, intente nuevamente', { type: 'danger'})
                    this.load = false
                    console.log(e)
                });
            }
        },
        //funcion para verificar que cada pregunta tenga una respuesta seleccionada
        checkErrors() {
            this.errors = []
            this.cuestionario.categorias.forEach(categoria => {
                let j = 1;
                categoria.preguntas.forEach(pregunta => {
                    pregunta.respuestas.every(respuesta => {
                        if (!respuesta.hasOwnProperty('pressed')) {
                            this.errors.push('error debe seleccionar una respuesta en la pregunta ' + j +' de la categoría '+ categoria.cat)
                            createToast(('error debe seleccionar una respuesta en la pregunta ' + j +' de la categoría '+ categoria.cat), {type: 'danger'})
                        } else if (respuesta.pressed == true && respuesta.hasOwnProperty('pressed')) {
                            return false
                        }
                        j++
                    });
                })
            });
            return
        },
        cleanRequest() {

            this.respuestasEstudiante.cuestionario.categorias.forEach(categoria => {
                categoria.preguntas.forEach(pregunta => {
                    //aplicamos un filtro para qutar todas las respuestas que no fueron seleccionadas
                    pregunta.respuestas = pregunta.respuestas.filter(item => item['pressed'] !== false);
                })
            });
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        currentUser() {
            return this.$store.state.auth.usr.persona;
        },
    },
    created() {
        this.cuestionarioId = this.$route.params.id
        if (!this.loggedIn) {
            this.$router.push({
                name: 'Home'
            });
        }
        this.respuestasEstudiante.estudiante = this.currentUser
        this.Getcuestionario()
    }
}
</script>

<style>
.section {
    font-size: 15px;
    font-weight: bold;
}
</style>
