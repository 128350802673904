<template>
  <div :id="cuestionario.id" ref="testHtml" class="container">
    <div class="cols space-between">
      <div>FCJYP {{ cuestionario.id }}</div>
      <div>UPU Materia: {{ cuestionario.nombre_materia }}</div>
    </div>
    <div class="rows" style="width: 90%">
      <div style="align-self: flex-start">Correo institucional:</div>
    </div>
    <h4>
      EVALUACIÓN DEL DESEMPEÑO DOCENTE DESDE LA PERCEPCIÓN DE LOS ESTUDIANTES
    </h4>
    <h5>Facultad {{ cuestionario.facultad }}</h5>
    <h5>Año Académico {{ cuestionario.periodo_academico }}</h5>
    <table>
      <tr>
        <td>
          Apellidos y nombres: {{ cuestionario.apellidos }}
          {{ cuestionario.nombres }}
        </td>
        <td>
          Año y Sección: {{ cuestionario.year }}, {{ cuestionario.seccion }}
        </td>
      </tr>
      <tr>
        <td colspan="2">Mención: {{ cuestionario.nombre_mencion }}</td>
      </tr>
      <tr>
        <td colspan="2">Materia: {{ cuestionario.nombre_materia }}</td>
      </tr>
    </table>
    <table class="center">
      <tr>
        <th style="background-color: #ced4da">
          Evaluación <br />
          Cualitativa
        </th>
        <th>Deficiente</th>
        <th>Regular</th>
        <th>Bueno</th>
        <th>Muy Bueno</th>
        <th>Excelente</th>
      </tr>
      <tr>
        <th rowspan="2" style="background-color: #ced4da">
          Evaluación <br />
          Cuantitativa
        </th>
        <td>1,000 - 1,999</td>
        <td>2,000 - 2,999</td>
        <td>3,000 - 3,999</td>
        <td>4,000 - 4,499</td>
        <td>4,500 - 5,000</td>
      </tr>
      <tr>
        <td>1,00% - 29,00%</td>
        <td>30,00% - 59,99%</td>
        <td>60,00% - 79,99%</td>
        <td>80,00% - 89,99%</td>
        <td>90,00% - 100,00%</td>
      </tr>
    </table>
    <table
      v-for="(categoria, index) in cuestionario.categorias"
      :key="index"
      v-show="categoria.categoria"
    >
      <tr v-if="categoria.categoria">
        <th style="background-color: #ced4da">Categoría</th>
        <th style="background-color: #ced4da">Aspectos Evaluados</th>
        <th style="background-color: #ced4da">Promedio</th>
      </tr>
      <tr v-for="(pregunta, pindex) in categoria.promedios" :key="pindex">
        <td
          class="vertical"
          :rowspan="categoria.promedios.length + 1"
          colspan="1"
          v-if="pindex == 0"
        >
          <p style="margin-block: 20px; font-weight: bold">
            {{ categoria.categoria }}
          </p>
        </td>
        <td>{{ pregunta.pregunta }}</td>
        <td>{{ pregunta.valor }}</td>
      </tr>
      <tr v-if="categoria.categoria">
        <td style="font-weight: bold">Promedio de la categoría o dimensión</td>
        <td>{{ categoria.promedioSeccion }}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td style="text-align: center">
          Promedio Total <br />
          {{ cuestionario.categorias.promedioTotal }}
        </td>
        <td style="text-align: center">
          Evaluación Cualitativa <br />
          {{ getEstimacion(cuestionario.categorias.promedioTotal) }}
        </td>
      </tr>
    </table>
  </div>
  <button class="btn" @click="print(cuestionario.id)">Exportar Pdf</button>
</template>

<script>
export default {
  props: ["cuestionario"],
  methods: {
    print(id) {
      this.$htmlToPaper(id)
    },
    getEstimacion(promediostr) {
      var promedio = parseFloat(promediostr);
      if (promedio > 0 && promedio < 2) {
        return "Deficiente";
      } else if (promedio >= 2 && promedio < 3) {
        return "Regular";
      } else if (promedio >= 3 && promedio < 4) {
        return "Bueno";
      } else if (promedio >= 4 && promedio <= 4.49) {
        return "Muy Bueno";
      } else if (promedio >= 4.5 && promedio <= 5) {
        return "Excelente";
      }
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #fff;
}
.vertical {
  writing-mode: vertical-rl;
  text-align: center;
  vertical-align: top;
}
.space-between {
  width: 90%;
  margin-inline: auto;
  justify-content: space-between;
}
table,
th,
td {
  padding: 7px;
  text-align: left;
  border: 1px solid;
  color: #000;
  border-spacing: 0px;
}
table {
  border: 0px;
  display: table;
  margin-block: 10px;
  margin-inline: auto;
  width: 90%;
}
.center > th {
  text-align: center;
}
</style>
