const axios = require('axios')

const API_URL = process.env.VUE_APP_API_URL

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/auth/login', {
        cedula: user.cedula,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          sessionStorage.setItem('token', JSON.stringify(response.data));
        }

        return response.data;
      }).catch((e) => console.log(e));
  }

  verifyUser(token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token
    axios.defaults.headers.common["Accept"] = "*/*"
    return axios
      .post(API_URL + '/auth/me')
      .then(response => {
        sessionStorage.setItem('user', JSON.stringify(response.data))

        return response.data;
      }).catch((e) => console.log(e));
  }

  logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
  }
}

export default new AuthService();