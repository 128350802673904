<template>
  <Loading
    v-if="load"
    :message="'Espere se están cargando los datos de los profesores...'"
  />
  <div class="cols" v-if="!load">
    <SidePanel />

    <div v-if="!load" class="item-wrapper one">
      <div class="item my-2">
        <h3>Carga de Archivo Datos Docentes</h3>
      </div>
      <div class="item">
        <form
          @submit.prevent="uploadFile"
          method="post"
          enctype="multipart/form-data"
        >
          <div class="item-inner">
            <div class="item-content">
              <div class="image-upload">
                <label style="cursor: pointer" for="file_upload">
                  <img src="" alt="" class="uploaded-image" />
                  <div class="h-100">
                    <div class="dplay-tbl">
                      <div class="dplay-tbl-cell">
                        <font-awesome-icon
                          icon="cloud-arrow-up"
                          style="font-size: 80px"
                        />
                        <h5><b>Seleccione el archivo a subir</b></h5>
                        <h6 class="mt-10 mb-70">
                          o arrastre el archivo hacia aca
                        </h6>
                        <h6 v-if="file">
                          Archivo seleccionado: {{ file.name }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <!--upload-content-->
                  <input
                    type="file"
                    name="file"
                    class="image-input"
                    @change="handleFileUpload"
                  />
                </label>
              </div>
            </div>
            <!--item-content-->
          </div>
          <button class="btn-success" :disabled="submit" type="submit">
            Cargar Archivo
          </button>
          <!--item-inner-->
        </form>
      </div>
      <div class="item">
        <div class="item-inner">
          <div class="item-content">
            <center>
              <h5>
                Recuerde que el formato del excel debe coincidir con la
                siguiente imagen, manteniendo los encabezados a partir fila 1A:
              </h5>
              <br />
              <img src="../assets/images/modelo_carga_profesores.png" />
            </center>
          </div>
        </div>
      </div>
      <!--item-->
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;
import Loading from "./Loading.vue";
import SidePanel from "./SidePanelAdmin.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default {
  components: {
    Loading,
    SidePanel,
  },
  data() {
    return {
      file: null,
      load: false,
      fileTypes: [
        ".csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      submit: true,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.fileTypes.includes(this.file.type)) {
        this.load = false;
        this.submit = true;
        createToast("Elija un archivo con formato valido (.csv, .xls, xlsx)", {
          type: "danger",
        });
      } else {
        this.submit = false;
      }
    },
    async uploadFile() {
      if (this.fileTypes.includes(this.file.type) && this.file != null) {
        this.load = true;
        var formData = new FormData();
        formData.append("File", this.file);
        await axios
          .post(API_URL + "/data/upload/profesores", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            if (
              response.data ==
              "Se han Cargado los datos de los profesores con Exito!."
            ) {
              createToast(response.data, {
                type: "success",
                onLoad: this.$router.push({
                  name: "configuracion",
                }),
              });
            } else if (response.data == "Ha ocurrido algun error.") {
              createToast(response.data, { type: "danger" });
              createToast(
                "Asegúrese de cumplir con el formato que se le indica en la parte posterior, los encabezados de la tabla deben tener el nombre exactamente igual como se muestra en la imagen.",
                { type: "warning" }
              );
              this.load = false;
              this.submit = true;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.load = false;
        this.submit = false;
        createToast(
          "no ha seleccionado un archivo, seleccione un archivo con formato valido (.csv, .xls, xlsx)",
          {
            type: "danger",
          }
        );
      }
    },
  },
};
</script>

<style scoped src="../assets/bootstrap.min.css"></style>
<style scoped>
body {
  background: #eee;
}

.item .item-content {
  padding: 30px 35px;
}

.image-upload {
  width: 100%;
  height: 300px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  background: #f8f8f9;
  color: #666;
  overflow: hidden;
}

.item-wrapper form img {
  margin-bottom: 20px;
  width: auto;
  height: auto;
  max-height: 400px;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
}

.image-upload img {
  height: 100% !important;
  width: auto !important;
  border-radius: 0px;
  margin: 0 auto;
}

.image-upload i {
  font-size: 6em;
  color: #ccc;
}

.image-upload input {
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.item-wrapper input {
  height: 100%;
  line-height: 43px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}
</style>
