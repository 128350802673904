<template>
  <div class="tblcont">
    <table class="cell-border stripe" id="table">
      <thead>
        <tr>
          <th rowspan="2">Nombres profesor</th>
          <th rowspan="2">Apellidos profesor</th>
          <th
            v-for="(cat, index) in colGroup"
            :key="index"
            :colspan="cat.colspan"
          >
            {{ cat.cat }}
          </th>
          <th rowspan="2">Estudiantes Encuestados</th>
          <th rowspan="2">Respondidos</th>
          <th rowspan="2">Promedio final</th>
        </tr>
        <tr>
          <th v-for="(preg, index) in colSubGroup" :key="index">
            {{ preg.preg }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(encuesta, index) in datos" :key="index">
          <td>{{ encuesta.nombres }}</td>
          <td>{{ encuesta.apellidos }}</td>
          <td v-for="(valores, j) in encuesta.valores" :key="j">
            {{ valores.valor }}
          </td>
          <td>{{encuesta.promedios.total}}</td>
          <td>{{encuesta.promedios.respondidas}}</td>
          <td>{{encuesta.promedios.promedioTotal}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
window.JSZip = require("jszip/dist/jszip.js");
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const langJson = require("../assets/es-ES.json");

export default {
  data() {
    return {
    };
  },
  props: ["datos", "colGroup", "colSubGroup"],
  mounted() {
      $("#table").DataTable({
        language: langJson,
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        dom: "frtipB",
        buttons: [{
            extend: 'excel',
            text: 'Exportar a archivo excel',
            exportOptions: {
                modifier: {
                    page: 'current'
                }
            }
        }],
      });
  },
  methods: {
    
  },
};
</script>

<style>
td {
  color: black;
}
.tblcont {
    background-color: aliceblue;
    width: 1000px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 200px;
    overflow-x: scroll;
}
.buttons-excel {
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
  background: linear-gradient(#0032A0, #003fc7);
  color: #fff;
  text-decoration: none !important;
  cursor: pointer;
  margin-top: 50px;
  border: none;
}
.buttons-excel:hover {
  background: linear-gradient(#0040c9, #0953f2);
}
</style>
