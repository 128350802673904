<template lang="">
<div class="cols">
<SidePanel />

<div class="container">
    <div class="container" style="padding-top: 0;">
        <h3>En esta página podrá visualizar las estadísticas de los resultados de los profesores</h3>
        <div class="small-container">

        <h3>Ver Resultados de cuestionarios.</h3>
        <div class="form-container-col">
            <div class="cols" style="column-gap: 10px; justify-content: space-between">
                <div class="rows">
                    <label>Seleccione una mención o carrera</label>
                    <select class="form-field-alt" v-model="mencion_id" @change="getYear(mencion_id)" :disabled="allMenciones">
                        <option v-for="(mencion, index) in menciones" :key="index" :value="mencion.id">{{ mencion.nombre_mencion }}</option>
                    </select>
                </div>
                <div class="rows">
                    <label>Todas las menciones</label>
                    <input type="checkbox" class="form-field" @click="allMenciones = !allMenciones">
                </div>
            </div>
            <div class="cols" style="column-gap: 10px; justify-content: space-between">
                <div class="rows">
                    <label>Seleccione el año</label>
                    <select class="form-field-alt" v-model="year" @change="getPeriodosEncuestas(mencion_id, year)" :disabled="allYears">
                        <option v-for="(year, index) in añosMencion" :key="index" :value="year.year">{{ year.year }}</option>
                    </select>
                </div>
                <div class="rows">
                    <label>Todos los años</label>
                    <input type="checkbox" class="form-field" @click="allYears = !allYears">
                </div>
            </div>
            <div class="cols" style="column-gap: 10px; justify-content: space-between">
                <div class="rows">
                    <label>Seleccione el periodo academico</label>
                    <select class="form-field-alt" v-model="period" :disabled="allPeriods">
                        <option v-for="(period, index) in periods" :key="index" :value="period.year">{{ period.year }}</option>
                    </select>
                </div>
                <div class="rows">
                    <label>Todos los periodos</label>
                    <input type="checkbox" class="form-field" @click="allPeriods = !allPeriods">
                </div>
            </div>
            <label>Buscar Profesor</label>
            <v-select v-model="selectedProfesor" :options="listaBusquedaProfesores" placeholder="Nombre o Apellido del Profesor/a" @click="GetProfesores">
                <template #no-options="{ search, searching, loading }">
                    No hay opciones.
                </template>
            </v-select>
            
            <button class="btn-success" @click="GetResultados()">
                <font-awesome-icon icon="magnifying-glass" />
                Buscar Resultados
            </button>
        </div>
    </div>
        <div class="small-container">
            <!-- <PollResultsTable v-if="encuestas.preguntasRespuestas.length > 0" :encuestas="encuestas.preguntasRespuestas"/> -->
        </div>
        <div class="small-container" v-for="(encuesta, index) in encuestas" :key="index">
            <h4 v-if="encuesta.categorias.respondidas <= 0">Aún no hay estadísticas para el profesor seleccionado.</h4>
            <!-- <h3 v-if="encuesta.categorias.respondidas > 0">Tabla con todas las respuestas</h3>
            <RespuestasTable v-if="encuesta.categorias.respondidas > 0" :categorias="encuesta.categorias" /> -->
            <div class="graph-background" v-if="encuesta.categorias.respondidas > 0">
                <h4>Grafica promedio por preguntas</h4>
                <h3>Materia: {{encuesta.nombre_materia}}</h3>
                <h4>Profesor(a): {{encuesta.apellidos}} {{encuesta.nombres}}</h4>
                <Chart v-if="encuesta.categorias.respondidas > 0" :promedios="encuesta.categorias" :titulo="encuesta.nombre_materia + ' ' + encuesta.apellidos + ' ' + encuesta.nombres" />
            </div>
            <div v-if="encuesta.categorias.respondidas > 0" class="est-detalles">
                <h3>Materia: {{encuesta.nombre_materia}}</h3>
                <h4>Profesor(a): {{encuesta.apellidos}} {{encuesta.nombres}}</h4>
                <PieChart v-if="encuesta.categorias" :promedios="encuesta.categorias"/>
            </div>
            <ModeloImprimir v-if="encuesta.categorias.respondidas > 0" :cuestionario="encuesta"/>
        </div>
    </div>
</div>
</div>
</template>

<script>
const axios = require('axios')
import PieChart from './PieChart.vue'
import SidePanel from './SidePanelAdmin.vue'
import Chart from './Chart.vue'
import RespuestasTable from './RespuestasTable.vue'
import ModeloImprimir from './ModeloImprimir.vue'
import {
    createToast
} from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

const API_URL = process.env.VUE_APP_API_URL
export default {
    components: { PieChart, SidePanel, Chart, RespuestasTable, ModeloImprimir },
    data() {
        return {
            encuestas: [],
            añosMencion: '',
            menciones: '',
            materias: '',
            mencion_id: '',
            year: '',
            period: '',
            profesores: [],
            listaBusquedaProfesores: [],
            messages: [],
            periods: '',
            allMenciones: false,
            allYears: false,
            allPeriods: false,
            selectedProfesor: '',
            colGroup: [],
            childCols: []
        }
    },
    methods: {
        async GetProfesores() {
            this.listaBusquedaProfesores = []
            if(this.allMenciones && !this.allYears && !this.allPeriods) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/all' + '/year/' + this.year + '/periodo/' + this.period ).then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else if(this.allYears && !this.allMenciones && !this.allPeriods) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/' + this.mencion_id + '/year/all' + '/periodo/' + this.period ).then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else if(this.allYears && this.allMenciones && !this.allPeriods) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/all' + '/year/all' + '/periodo/' + this.period ).then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else if(this.allMenciones && this.allPeriods && !this.allYears) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/all' + '/year/' + this.year + '/periodo/all').then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else if(this.allYears && this.allMenciones && this.allPeriods) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/all' + '/year/all' + '/periodo/all').then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else if(!this.allMenciones && !this.allYears && this.allPeriods) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/' + this.mencion_id + '/year/' + this.year + '/periodo/all').then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else if(!this.allMenciones && this.allYears && this.allPeriods) {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/' + this.mencion_id + '/year/all' + '/periodo/all').then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            } else {
                await axios.get(API_URL + '/estadisticas/profesores/mencion/' + this.mencion_id + '/year/' + this.year + '/periodo/' + this.period ).then((response) => {
                    this.profesores = response.data
                }).catch((e) => {
                    console.log(e)
                })
            }
            this.profesores.forEach(profesor => {
                this.listaBusquedaProfesores.push(profesor.nombres + ', ' + profesor.apellidos)
            })
        },
        async getYear(mencion_id) {
            await axios.get(API_URL + '/mencion/' + mencion_id + '/años').then((response) => {
                this.añosMencion = response.data
            }).catch((e) => {
                console.log(e)
            })
        },
        //metodo para obtener todas las encuestas con respuestas de estudiantes
        async getPeriodosEncuestas(mencion_id, year) {
            await axios.get(API_URL + '/encuestas/' + mencion_id + '/' + year + '/periodos').then((response) => {
                this.periods = response.data
            }).catch((e) => {
                console.log(e)
            })
        },
        arrangeCols() {
            const data = this.encuestas;
            const cols = [];
            const childCols = [];
            Object.keys(data).forEach(function (key) {
                if (data[key].hasOwnProperty("total")) {
                Object.keys(data[0][key]).forEach(function (index) {
                    if (data[0][key][index].hasOwnProperty("categoria")) {
                    cols.push({
                        cat: data[0][key][index].categoria,
                        colspan: data[0][key][index].promedios.length,
                    });
                    data[0][key][index].promedios.forEach((promedio) => {
                        childCols.push({ preg: promedio.pregunta });
                    });
                    }
                });
                }
            });
            this.colGroup = cols;
            this.colSubGroup = childCols;
        },
        async getMenciones() {
            await axios.get(API_URL + '/menciones').then((response) => {
                this.menciones = response.data
            }).catch((e) => {
                console.log(e)
            })
        },
        async GetResultados() {
            if(this.selectedProfesor == '') {
                createToast('Debe seleccionar un profesor', { type: 'danger'})
            } else {
                this.encuestas = []
                const req = {
                    profesor: this.selectedProfesor,
                    mencion: (this.allMenciones) ? 'all' : this.mencion_id,
                    year: (this.allYears) ? 'all' : this.year,
                    period: (this.allPeriods) ? 'all' : this.period
                }
                await axios.post(API_URL + '/estadisticas/profesor', req)
                .then((response) => {
                    this.encuestas = response.data
                    console.log(this.encuestas)
                })
                .catch((e) => { console.log(e) })
            }
        }
    },
    created() {
        this.getMenciones()
    },
}
</script>

<style lang="">

</style>
