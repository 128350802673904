<template>
<div class="col">
    <div class="row">
        <div>
            <h4>Total Encuestados</h4>
            <h5>Estudiantes: {{promedios.total}}</h5>
            <h5>Estudiantes que respondieron: {{promedios.respondidas}}</h5>
        </div>
        <div class="col">
            <h4>Promedio Total</h4>
            <div class="pie animate no-round" :style="`--p:${promedios.porcentajeTotal};--c:#013a63`">{{promedios.promedioTotal}}/5</div>
            <div :class="getEstimacion(promedios.promedioTotal)">{{ getEstimacion(promedios.promedioTotal).replace("-", " ") }}</div>
        </div>
    </div>

    <h4>Promedios Categorías</h4>
    <div class="row">
        <div v-for="(promedio, index) in promedios" :key="index" v-show="promedio.categoria">
            <div v-if="promedio.categoria">
                <div class="label" v-show="promedio.show">Categoría: {{promedio.categoria}}</div>  
                <h5 class="phover" @mouseenter="showlabel(promedio)" @mouseleave="showlabel(promedio)">Categoria {{parseInt(index) + 1}}</h5>
                <div class="pie animate no-round" :style="`--p:${promedio.porcentajeSeccion};--c:#014f86`">{{promedio.promedioSeccion}}/5</div>
                <div :class="getEstimacion(promedio.promedioSeccion)">{{ getEstimacion(promedio.promedioSeccion).replace("-", " ") }}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['promedios'],

    methods: {
        getEstimacion(promediostr) {
            var promedio = parseFloat(promediostr)
            if(promedio > 0 && promedio < 2) {
                return "Deficiente"
            } else if(promedio >= 2 && promedio < 3) {
                return "Regular"
            } else if(promedio >= 3 && promedio < 4) {
                return "Bueno"
            } else if(promedio >= 4 && promedio <= 4.49){
                return "Muy-Bueno"
            } else if (promedio >= 4.5 && promedio <= 5) {
                return "Excelente"
            }
        },
        showlabel(pregunta) {
            if (pregunta.show == true) {
                pregunta.show = false
            } else {
                pregunta.show = true
            }
        }
    }
}
</script>

<style>
@property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 20px;
}

.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.pie {
    --p: 20;
    --b: 22px;
    --c: darkred;
    --w: 150px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 25px;
    font-weight: bold;
    font-family: sans-serif;
}

.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.pie:before {
    inset: 0;
    background:
        radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
    inset: calc(50% - var(--b)/2);
    background: var(--c);
    transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.animate {
    animation: p 1s .5s both;
}

.no-round:before {
    background-size: 0 0, auto;
}

.no-round:after {
    content: none;
}

.phover {
    padding: 7px;
    border-radius: 20px;
    background: #fff;
}

@keyframes p {
    from {
        --p: 0
    }
}

.Deficiente {
    margin: 5px;
    padding: 10px;
    background-color: #ff0000;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
}
.Regular {
    margin: 5px;
    padding: 10px;
    background-color: #ff8700;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
}
.Bueno {
    margin: 5px;
    padding: 10px;
    background-color: #ffd300;
    color: #051923;
    font-weight: bold;
    border-radius: 20px;
}
.Muy-Bueno {
    margin: 5px;
    padding: 10px;
    background-color: #deff0a;
    color: #051923;
    font-weight: bold;
    border-radius: 20px;
}
.Excelente {
    margin: 5px;
    padding: 10px;
    background-color: #a1ff0a;
    color: #051923;
    font-weight: bold;
    border-radius: 20px;
}

body {
    background: #ddd;
}
</style>
