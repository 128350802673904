<template>
  <div class="cols">
    <SidePanel />
    <div class="container">
      <h3>
        Bienvenido/a {{ currentUser.apellidos.split(" ")[0] }}
        {{ currentUser.nombres.split(" ")[0] }}, este es el panel de
        Administrador.
      </h3>
      <h3>Estatus General</h3>
      <div class="cards-container">
        <div class="card-submenu">
          <div class="card-item">
            <h3>Profesores</h3>
          </div>
          <div class="card-item" style="font-size: 35px">
            <font-awesome-icon style="margin-top: 10px" icon="chalkboard-user" />
            <p style="font-size: 20px; margin-bottom: 10px; margin-top: 0">
              {{ stats.cantidadProfesores.cantidadProfesores }}
            </p>
          </div>
        </div>
        <div class="card-submenu">
          <div class="card-item">
            <h3>Estudiantes</h3>
          </div>
          <div class="card-item" style="font-size: 35px">
            <font-awesome-icon style="margin-top: 10px" icon="user-graduate" />
            <p style="font-size: 20px; margin-bottom: 10px; margin-top: 0">
              {{ stats.cantidadEstudiantes }}
            </p>
          </div>
        </div>
        <div class="card-submenu">
          <div class="card-item">
            <h3>Cuestionarios</h3>
          </div>
          <div class="card-item" style="font-size: 35px">
            <font-awesome-icon style="margin-top: 10px" icon="square-poll-horizontal" />
            <p style="font-size: 20px; margin-bottom: 10px; margin-top: 0">
              {{ stats.cantidadEncuestas }}
            </p>
          </div>
        </div>
        <div class="card-submenu">
          <div class="card-item" style="width: 115px">
            <h3>Cuestionarios Completados</h3>
          </div>
          <div class="card-item" style="font-size: 35px">
            <font-awesome-icon style="margin-top: 10px" icon="check-double" />
            <p style="font-size: 20px; margin-bottom: 10px; margin-top: 0">
              {{ stats.encuestasRespondidas }}
            </p>
          </div>
        </div>
        <div class="card-submenu">
          <div class="card-item" style="width: 115px">
            <h3>Cuestionarios Completados Hoy</h3>
          </div>
          <div class="card-item" style="font-size: 35px">
            <font-awesome-icon style="margin-top: 10px" icon="calendar-day" />
            <p style="font-size: 20px; margin-bottom: 10px; margin-top: 0">
              {{ stats.encuestasRespondidasHoy }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../components/SidePanelAdmin.vue";
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;

export default {
  components: {
    SidePanel,
  },
  data() {
    return {
      stats: {
        cantidadEstudiantes: '',
        cantidadProfesores: {
          cantidadProfesores: ''
        },
        encuestasRespondidasHoy: '',
        encuestasRespondidas: '',
        cantidadEncuestas: '',
      },
      detailAct: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.usr.persona;
    },
  },
  methods: {
    async getGeneralStats() {
      await axios
        .get(API_URL + "/estadisticas-generales")
        .then((response) => {
          this.stats = response.data;
        })
        .catch((e) => console.log(e));
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push({
        name: "Home",
      });
    } else {
      this.getGeneralStats();
    }
  },
};
</script>

<style scoped>
.cards-container {
  width: min(100% - 2rem);
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.card-item {
  margin-inline: 20px;
  margin-block: 7px;
}

.details {
  width: inherit;
  height: auto;
  padding-inline: 4px;
  background: linear-gradient(#1c4fbc, #255edb);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

</style>
