<template>
  <div class="cols">
    <SidePanel />
    <div class="cols" style="column-gap: 20px; padding-inline: 15px; background-color: #DDDDDD; justify-content:center; align-items:center;">
      <div class="container" style="background-color: #DDDDDD">
        <h3>Crear Cuenta Administrador</h3>
        <div class="logo-bg">
          Datos Necesarios
          <form @submit.prevent="createAdmin" class="flex-col" method="post">
            <div class="input-row">
              <div class="form-field-cont">
                <select
                  class="form-field form-ssm"
                  @change="handleChange"
                  v-model="usuario.nacionalidad"
                >
                  <option value="V" selected data-foo="V">V</option>
                  <option value="E" data-foo="E">E</option>
                </select>
              </div>
              <div class="form-field-cont">
                <font-awesome-icon icon="id-card" class="form-icon" />
                <input
                  class="form-field"
                  type="text"
                  v-model="usuario.cedula"
                  placeholder="Numero de cedula"
                />
              </div>
            </div>

            <div class="form-field-cont">
              <font-awesome-icon icon="user" class="form-icon" />
              <input
                class="form-field"
                type="text"
                v-model="usuario.nombres"
                placeholder="Nombres"
              />
            </div>
            <div class="form-field-cont">
              <font-awesome-icon icon="signature" class="form-icon" />
              <input
                class="form-field"
                type="text"
                v-model="usuario.apellidos"
                placeholder="Apellidos"
              />
            </div>
            <div class="form-field-cont">
              <font-awesome-icon icon="at" class="form-icon" />
              <input
                class="form-field"
                type="text"
                v-model="usuario.email"
                placeholder="Correo Electrónico"
              />
            </div>
            <div class="form-field-cont">
              <font-awesome-icon icon="lock" class="form-icon" />
              <input
                class="form-field"
                type="password"
                v-model="usuario.password"
                placeholder="Contraseña"
              />
            </div>
            <div class="form-field-cont">
              <font-awesome-icon icon="lock" class="form-icon" />
              <input
                class="form-field"
                type="password"
                v-model="usuario.passwordRepeat"
                placeholder="Repita la Contraseña"
              />
            </div>
            <button :disabled="submit" type="submit" class="btn-blue">
              Enviar
              <font-awesome-icon
                v-if="!submit"
                icon="arrow-up-right-from-square"
              /><font-awesome-icon
                v-if="submit"
                class="loader"
                icon="spinner"
              />
            </button>
          </form>
        </div>
      </div>
      <div class="container" style="background-color: #DDDDDD">
        <h3>Lista Administradores</h3>
        <DTAdministradores @reload="getListaAdmins" v-if="admins" :datos="admins" />
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../components/SidePanelAdmin.vue";
import DTAdministradores from '../components/DTAdministradores.vue'
const axios = require("axios");
const API_URL = process.env.VUE_APP_API_URL;
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default {
  components: {
    SidePanel,
    DTAdministradores
  },
  data() {
    return {
      usuario: {
        cedula: "",
        nombres: "",
        apellidos: "",
        email: "",
        nacionalidad: "V",
        password: "",
        passwordRepeat: "",
      },
      admins: [],
      submit: false,
      errors: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$store.state.auth.usr = JSON.parse(sessionStorage.getItem("user"));
      this.checkUserType();
      this.getListaAdmins();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    async getListaAdmins() {
      this.admins = []
      await axios.get(API_URL + '/lista-admin').then((res) => {
        this.admins = res.data
      }).catch((e) => { console.log(e)})
    },
    handleChange(e) {
      if (e.target.options.selectedIndex > -1) {
        console.log(
          e.target.options[e.target.options.selectedIndex].dataset.foo
        );
      }
    },
    checkUserType() {
      if (this.loggedIn) {
        if (this.$store.state.auth.usr.persona.tipo === "Administrador") {
        } else if (this.$store.state.auth.usr.persona.tipo === "Estudiante") {
          this.$router.push("/panel-estudiante");
        }
      }
    },
    checkErrors() {
      this.errors = [];
      if (this.usuario.nombres == "") {
        this.errors.push("Debe introducir un nombre.");
      }
      if (this.usuario.apellidos == "") {
        this.errors.push("Debe introducir un apellido.");
      }
      if (this.usuario.cedula == "") {
        this.errors.push("Debe introducir la cédula.");
      } else if (!/[0-9]+/.test(this.usuario.cedula)) {
        this.errors.push("La cedula solo debe tener numeros");
      }
      if (this.usuario.email == "") {
        this.errors.push("Debe introducir el correo.");
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.usuario.email)) {
        this.errors.push("Correo electrónico invalido.");
      }
      if (this.usuario.password == "") {
        this.errors.push("Debe introducir una contraseña.");
      } else if (this.usuario.password != this.usuario.passwordRepeat) {
        this.errors.push("Las contraseñas deben coincidir.");
      }
    },
    async createAdmin() {
      this.submit = true;
      await this.checkErrors();
      if (this.errors.length > 0) {
        this.errors.forEach((error) => {
          createToast(error, { type: "danger" });
        });
        this.submit = false;
      } else {
        await axios
          .post(API_URL + "/crear-admin", this.usuario)
          .then((response) => {
            if (
              response.data ==
              "ya existe una persona registrada con esta cedula"
            ) {
              createToast(
                "Ya existe una persona registrada con este número de cédula.",
                { type: "danger" }
              );
              this.submit = false;
            } else if (
              response.data == "ya existe un usuario con este correo"
            ) {
              createToast("Ya existe una persona registrada con este correo.", {
                type: "danger",
              });
              this.submit = false;
            } else if (response.status == 200) {
              createToast("Administrador creado con éxito.", {
                type: "success",
              });
              this.$router.push("/panel-administrador");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style>
</style>