function addStyles(win, styles) {
    styles.forEach((style) => {
      let link = win.document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("href", style);
      win.document.getElementsByTagName("head")[0].appendChild(link);
    });
  }
  
  const VueHtmlToPaper = {
    install(app, options = {}) {
      app.config.globalProperties.$htmlToPaper = (
        el,
        localOptions,
        cb = () => true
      ) => {
        let 
          defaultStyles = [];
        let {
          styles = defaultStyles
        } = options;
  
        // If has localOptions
        // TODO: improve logic
        if (localOptions) {
          if (localOptions.styles) styles = localOptions.styles;
        }
  
        const element = window.document.getElementById(el);
  
        if (!element) {
          alert(`Element to print #${el} not found!`);
          return;
        }
  
        var ifprint = document.createElement("iframe");
        document.body.appendChild(ifprint);
        ifprint.setAttribute("style","height:0;width:0;");

        const win = ifprint.contentWindow;
  
        win.document.write(`
          <html>
            <head>
            <style>
              body{
                font-family: arial;
              }
              .cols {
                display: flex;
                flex-direction: row;
            }
            
            .rows {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
            }
            .container {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              margin-right: 0px;
              padding-top: 60px;
              min-height: 100vh;
              width: 100%;
              background-color: #fff;
          }
          .vertical {
            writing-mode: vertical-rl;
            text-align: center;
            vertical-align: top;
          }
          .space-between {
            width: 90%;
            margin-inline: auto;
            justify-content: space-between;
          }
          table,
          th,
          td {
            padding: 7px;
            text-align: left;
            border: 1px solid;
            color: #000;
            border-spacing: 0px;
          }
          table {
            border: 0px;
            display: table;
            margin-block: 5px;
            margin-inline: auto;
            width: 90%;
          }
          .center > th {
            text-align: center;
          }
          </style>
              <title>${window.document.title}</title>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);
  
        addStyles(win, styles);
        
  
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          document.body.removeChild(ifprint);
          cb();
        }, 1);
  
        return true;
      };
    }
  };
  
  export default VueHtmlToPaper;