<template>
  <div class="tblcont">
    <table class="cell-border compact stripe" id="table">
      <thead>
        <tr>
          <th>Nombres profesor</th>
          <th>Apellidos profesor</th>
          <th>Materia</th>
          <th>Año</th>
          <th>Sección</th>
          <th>Estudiantes Asignados</th>
          <th>Fecha Creado</th>
          <th>Fecha Límite</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(encuesta, index) in datos" :key="index">
          <td>{{ encuesta.nombres }}</td>
          <td>{{ encuesta.apellidos }}</td>
          <td>
            {{ encuesta.nombre_materia }}
          </td>
          <td>{{ encuesta.year }}</td>
          <td>{{ encuesta.seccion }}</td>
          <td>{{ encuesta.estudiantesAsignados }}</td>
          <td>{{ encuesta.fecha_creado }}</td>
          <td>{{ encuesta.fecha_limite }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
window.JSZip = require("jszip/dist/jszip.js");
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const langJson = require("../assets/es-ES.json");

export default {
  data() {
    return {
      columns: [],
      colGroup: [],
      colSubGroup: [],
    };
  },
  props: ["datos"],
  mounted() {
    $("#table").DataTable({
      language: langJson,
      pagingType: "full_numbers",
      pageLength: 10,
      processing: true,
      dom: "frtipB",
      buttons: [
        {
          extend: "excel",
          text: "Exportar a archivo excel",
          exportOptions: {
            modifier: {
              page: "current",
            },
          },
        },
      ],
    });
  },
  methods: {
    
  },
};
</script>

<style>
td {
  color: black;
}
.tblcont {
  background-color: aliceblue;
  width: 1000px;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 200px;
  overflow-x: scroll;
}
.buttons-excel {
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
  background: linear-gradient(#0032a0, #003fc7);
  color: #fff;
  text-decoration: none !important;
  cursor: pointer;
  margin-top: 50px;
  border: none;
}
.buttons-excel:hover {
  background: linear-gradient(#0040c9, #0953f2);
}
</style>
