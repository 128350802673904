<template>
  <div class="cols">
    <SidePanel />
    <div class="container">
      <div class="form-container-col" style="max-width: 600px">
        <h3>Seleccione un cuestionario en la siguiente lista</h3>

        <label>Seleccione el cuestionario</label>
        <v-select
          v-model="selectedEncuesta"
          :options="encuestas"
          placeholder="Titulo del cuestionario"
        >
          <template #no-options="{ search, searching, loading }">
            No hay opciones.
          </template>
        </v-select>
        <button class="btn-success" @click="GetResultados()">
          <font-awesome-icon icon="magnifying-glass" />
          Buscar Resultados
        </button>
      </div>
      <DTEncuestas
        v-if="datos"
        :datos="datos"
        :colGroup="colGroup"
        :colSubGroup="colSubGroup"
      />
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import SidePanel from "./SidePanelAdmin.vue";
import DTEncuestas from "./DTEncuestas.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  components: { SidePanel, DTEncuestas },
  data() {
    return {
      encuestas: "",
      selectedEncuesta: "",
      detallesEncuesta: "",
      datos: "",
      colGroup: "",
      colSubGroup: "",
    };
  },
  computed: {
    filterDatos() {
      return this.detallesEncuesta.filter((detalle) => {
        if (detalle.promedios.porcentajeTotal > 0) {
          return detalle;
        }
      });
    },
  },
  methods: {
    ValoresEncuesta(cuestionarios) {
      if (cuestionarios.length > 0) {
        this.datos = "";
        cuestionarios.forEach((cuestionario) => {
          const valores = [];
          Object.keys(cuestionario.promedios).forEach(function (jey) {
            if (cuestionario.promedios[jey].hasOwnProperty("promedios")) {
              cuestionario.promedios[jey].promedios.forEach((prom) => {
                valores.push({ valor: prom.valor });
              });
            }
          });
          cuestionario.valores = valores;
        });
        this.datos = cuestionarios;
      } else {
        this.datos = "";
        createToast(
          "Aún no hay respuestas de estudiantes para este cuestionario.",
          { type: "warning" }
        );
      }
    },
    arrangeCols() {
      const data = this.datos;
      const cols = [];
      const childCols = [];
      Object.keys(data[0]).forEach(function (key) {
        if (data[0][key].hasOwnProperty("total")) {
          Object.keys(data[0][key]).forEach(function (index) {
            if (data[0][key][index].hasOwnProperty("categoria")) {
              cols.push({
                cat: data[0][key][index].categoria,
                colspan: data[0][key][index].promedios.length,
              });
              data[0][key][index].promedios.forEach((promedio) => {
                childCols.push({ preg: promedio.pregunta });
              });
            }
          });
        }
      });
      this.colGroup = cols;
      this.colSubGroup = childCols;
    },
    async getEncuestasList() {
      await axios
        .get(API_URL + "/listaencuestas")
        .then((response) => {
          this.encuestas = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.encuestas.forEach((encuesta) => {
        encuesta.label =
          encuesta.titulo +
          ", " +
          encuesta.nombre_mencion +
          ", " +
          "Año " +
          encuesta.year +
          ", Sección " +
          encuesta.seccion;
      });
    },
    async GetResultados() {
      this.detallesEncuesta = null;
      await axios
        .get(
          API_URL +
            "/estadisticas/" +
            "encuesta/" +
            this.selectedEncuesta.cuest_id +
            "/mencion/" +
            this.selectedEncuesta.nombre_mencion +
            "/year/" +
            this.selectedEncuesta.year
        )
        .then((response) => {
          this.detallesEncuesta = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.ValoresEncuesta(this.filterDatos);
      this.arrangeCols();
    },
  },
  mounted() {
    this.getEncuestasList();
  },
};
</script>

<style></style>
