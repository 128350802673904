<template>
  <div>
    <div class="header">
      <div class="header-logo">
        <img src="@/assets/logo_ucat2.png" alt="" />
      </div>
    </div>
    <div class="header-links">
      <router-link class="header-link" to="/cambio-contrasena" v-if="loggedIn">
        Cambiar Contraseña
      </router-link>
      <div
        v-if="loggedIn"
        v-show="currentUser.tipo == 'Administrador'"
        class="header-link"
        @click="showLogout = !showLogout"
      >
        <font-awesome-icon icon="user-gear" class="fa" />
        Administrador: {{ currentUser.apellidos.split(" ")[0] }}
        {{ currentUser.nombres.split(" ")[0] }}
        <Transition>
          <div class="logout" v-if="showLogout" @click="logout" @mouseleave="showLogout = !showLogout">
            Cerrar Sesión <font-awesome-icon icon="arrow-right-from-bracket" />
          </div>
        </Transition>
      </div>
      <div
        v-if="loggedIn"
        v-show="currentUser.tipo == 'Estudiante'"
        class="header-link"
        @click="showLogout = !showLogout"
      >
        <font-awesome-icon icon="user-check" class="fa" />
        Estudiante: {{ currentUser.apellidos.split(" ")[0] }}
        {{ currentUser.nombres.split(" ")[0] }}
        <Transition>
          <div class="logout" v-if="showLogout" @click="logout" @mouseleave="showLogout = !showLogout">
            Cerrar Sesión <font-awesome-icon icon="arrow-right-from-bracket" />
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showLogout: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      if(this.loggedIn && this.$store.state.auth.usr) {
        return this.$store.state.auth.usr.persona;
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.logout {
  position: absolute !important;
  background: #fff;
  top: 195px;
  right: 20px;
  height: 25px;
  padding: 5px;
  padding-inline: 10px;
  border-radius: 10px;
  color: #0032a0;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
