<template>
  <ConfirmModal
    v-if="dialog"
    @cancel="dialog = false"
    @confirm="savePoll(), (dialog = false)"
    :message="'¿Esta seguro que desea crear el cuestionario?'"
  />
  <ConfirmModal
    v-if="confirmTemplate"
    @cancel="confirmTemplate = false"
    @confirm="applyTemplate(), (confirmTemplate = false)"
    :message="'¿Esta seguro que quiere usar esta plantilla?, al hacerlo se eliminaran las categorias y preguntas que esten en esta pantalla.'"
  />
  <Loading v-if="load" :message="'Espere se esta enviando su solicitud.'" />
  <div v-if="!load" class="cols">
    <SidePanel />
    <div class="container">
      <div class="small-container">
        <h3>Crear Cuestionario</h3>
        <p>
          Advertencia: si no aparecen menciones o carreras en la selección,
          usted debería dirigirse al menú de configuraciones y cargar los
          archivos que contienen la información de los profesores y estudiantes.
        </p>
        <div class="form-container-col">
          <label>Seleccione una mención o carrera</label>
          <select
            class="form-field-alt"
            v-model="newEncuesta.mencion_id"
            @change="getYear(newEncuesta.mencion_id)"
          >
            <option
              v-for="(mencion, index) in menciones"
              :key="index"
              :value="mencion.id"
            >
              {{ mencion.nombre_mencion }}
            </option>
          </select>
          <label>Seleccione el año</label>
          <select
            class="form-field-alt"
            v-model="newEncuesta.year"
            @change="
              añosMencion == null,
                getSecciones(newEncuesta.mencion_id, newEncuesta.year)
            "
          >
            <option
              v-for="(year, index) in añosMencion"
              :key="index"
              :value="year.year"
            >
              {{ year.year }}
            </option>
          </select>
          <label>Seleccione la sección</label>
          <select
            class="form-field-alt"
            v-model="newEncuesta.seccion"
            @change="
              getMateriasMencionSeccion(
                newEncuesta.mencion_id,
                newEncuesta.year,
                newEncuesta.seccion
              )
            "
          >
            <option
              v-for="(seccion, index) in secciones"
              :key="index"
              :value="seccion.seccion"
            >
              {{ seccion.seccion }}
            </option>
          </select>
        </div>
        <div class="dt" v-if="materias">
          <DTProfesores :materias="materias" />
          <p>
            El cuestionario se aplicara a todos los estudiantes que cursen las
            materias de los profesores que aparecen en la tabla
          </p>
          <p>¿A qué periodo académico desea asignar este cuestionario?</p>
          <select
            v-model="newEncuesta.periodo_academico"
            class="form-field-alt"
            id=""
          >
            <option
              v-for="(periodo, index) in periodos_academicos"
              :key="index"
              :value="periodo.periodo_academico"
            >
              {{ periodo.periodo_academico }}
            </option>
          </select>
        </div>
      </div>
      <div class="small-container" v-if="materias" style="margin-bottom: 50px">
        <div class="est-detalles" style="background-color: #fff">
          <h4>Introduzca el título del cuestionario</h4>
          <p>
            (siguiendo la nomenclatura [año/periodo {a, b, c}]) ejemplo: “2022 –
            A”
          </p>
          <textarea
            type="text"
            class="form-field"
            style="align-self: center; width: 250px"
            rows="3"
            v-model="newEncuesta.title"
            placeholder="Título del Cuestionario"
          />
        </div>
        <div class="est">
          <h4>¿Desea utilizar una plantilla?</h4>
          No<input
            type="checkbox"
            id=""
            @click="usingTemplate = false"
            :checked="!usingTemplate"
          />
          Si<input
            type="checkbox"
            id=""
            @click="usingTemplate = true"
            :checked="usingTemplate"
          />
          <div v-if="usingTemplate">
            <h5>Seleccione una plantilla</h5>
            <select
              v-model="plantilla"
              style="max-width: 200px"
              @change="getCategoriasYPreguntas(), (confirmTemplate = true)"
            >
              <option value="">(ninguna)</option>
              <option
                v-for="(plnt, index) in plantillas"
                :value="plnt.id"
                :key="index"
              >
                {{ plnt.titulo }}
              </option>
            </select>
          </div>
        </div>
        <div class="buttons-row" style="margin-bottom: 10px">
          <h4>Introduzca las fechas de inicio y final del cuestionario</h4>
          <!-- agregar :minDate="new Date()" para tener fechas validas -->
          <Datepicker
            style="margin-bottom: 15px"
            autoApply
            v-model="newEncuesta.fechaCreada"
            locale="es"
            :minDate="new Date()"
            :enableTimePicker="false"
            placeholder="Seleccione fecha Inicio"
          />
          <Datepicker
            autoApply
            :minDate="newEncuesta.fechaCreada"
            v-model="newEncuesta.fechaLimite"
            locale="es"
            :enableTimePicker="false"
            placeholder="Seleccione fecha final"
          />
        </div>

        <div
          v-for="(seccion, secIndex) in newEncuesta.secciones"
          :key="secIndex"
          class="seccion-container"
        >
          <div v-if="!seccion.edit">
            <p style="font-size: 16px; font-weight: bold">
              Categoría {{ secIndex + 1 }}: {{ seccion.titulo }}
            </p>
            <div class="buttons-row">
              <button class="btn-danger sm" @click="eraseCat(secIndex)">
                Eliminar Categoría <font-awesome-icon icon="trash-can" />
              </button>
              <button class="btn-warning sm" @click="seccion.edit = true">
                Editar Título Categoría
                <font-awesome-icon icon="pen-to-square" />
              </button>
            </div>
          </div>
          <div v-if="seccion.edit">
            <textarea
              type="text"
              class="form-field"
              style="width:250px;"
              rows="3"
              placeholder="Título de la categoría"
              v-model="seccion.titulo"
            />
            <button class="btn-success sm" @click="editSecc(seccion)">
              Guardar
              <font-awesome-icon icon="check" />
            </button>
          </div>
          <div
            class="questions"
            v-for="(question, index) in seccion.preguntas"
            :key="index"
          >
            <div class="form-sm-container" v-if="!question.saved">
              <h4>Introduzca el título de la pregunta</h4>
              <textarea
                type="text"
                rows="3"
                class="form-field"
                style="align-self: center; width: 170px"
                v-model="question.title"
                placeholder="Título de la Pregunta"
              />
              <h4>Opciones o respuestas de la pregunta</h4>
              <div class="question-radio" v-if="question.options">
                <div v-for="(option, index) in question.options" :key="index">
                  <input
                    class="form-field"
                    type="text"
                    :placeholder="'opción ' + (index + 1)"
                    v-model="option.nombre"
                  />
                  <input
                    style="inline: block; width: 30px"
                    class="form-field"
                    type="text"
                    :placeholder="'valor ' + (index + 1)"
                    v-model="option.valor"
                  />
                </div>
              </div>
              <div class="buttons-row">
                <!--<button @click="addOption(index)" style="margin-top:20px" class="btn-success sm" v-if="!question.saved">Agregar opcion</button> -->
                <button
                  @click="addOptionsTemplate(secIndex, index)"
                  class="btn-success sm"
                  style="margin-top: 20px"
                  v-if="!question.saved"
                >
                  Usar Plantilla <font-awesome-icon icon="list-check" />
                </button>
              </div>
              <button
                @click="save(secIndex, index)"
                style="margin-top: 20px"
                class="btn-success"
                v-if="!question.saved"
              >
                Guardar Pregunta <font-awesome-icon icon="floppy-disk" />
              </button>
            </div>
            <div class="question-radio" v-if="question.saved">
              <h4>Pregunta Nro. {{ index + 1 }} {{ question.title }}</h4>
              <div v-for="(options, index) in question.options" :key="index">
                <input
                  type="radio"
                  class="form-field"
                  :name="'radio' + index"
                  :id="'radio' + index"
                />
                <label :for="'radio' + index">{{ options.nombre }}</label>
              </div>
            </div>
            <div class="buttons-row">
              <button
                @click="erase(secIndex, index)"
                class="btn-danger sm"
                v-if="question.saved"
              >
                Eliminar <font-awesome-icon icon="trash-can" />
              </button>
              <button
                @click="edit(secIndex, index)"
                class="btn-warning sm"
                v-if="question.saved"
              >
                Editar <font-awesome-icon icon="pen-to-square" />
              </button>
            </div>
          </div>
          <button
            @click="addInputs(secIndex)"
            class="btn"
            style="margin-top: 20px"
          >
            Agregar Pregunta <font-awesome-icon icon="circle-plus" />
          </button>
        </div>

        <p>Agregue las categorías del cuestionario</p>
        <textarea
          class="form-field"
          type="text"
          rows="3"
          placeholder="Título de la categoría"
          style="margin-top: 10px; width: 200px"
          v-model="seccion"
        />
        <button @click="addSection" class="btn" style="margin-top: 20px">
          Agregar Categoría <font-awesome-icon icon="circle-plus" />
        </button>
        <h4>Guardar Plantilla</h4>
        <input type="checkbox" v-model="newEncuesta.esPlantilla" />
        <h4>¿Todo Listo?</h4>
        <button @click="dialog = true" :disabled="disabled" class="btn-success">
          Guardar Cuestionario <font-awesome-icon icon="floppy-disk" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import Loading from "./Loading.vue";
import SidePanel from "./SidePanelAdmin.vue";
import DTProfesores from "./DTProfesores.vue";
import ConfirmModal from "./ConfirmModal.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

const API_URL = process.env.VUE_APP_API_URL;
export default {
  components: {
    Loading,
    SidePanel,
    DTProfesores,
    ConfirmModal,
  },
  data() {
    return {
      newEncuesta: {
        title: "",
        fechaCreada: "",
        fechaLimite: "",
        mencion_id: "",
        materias: [],
        year: "",
        secciones: [],
        periodo_academico: "",
        esPlantilla: false,
      },
      newQuestions: [],
      newQuestion: {
        type: "",
      },
      periodos_academicos: [],
      disabled: false,
      load: false,
      confirm: false,
      usingTemplate: false,
      dialog: false,
      cancel: false,
      errors: [],
      secciones: [],
      categorias: [],
      plantilla: "",
      plantillas: [],
      seccion: "",
      materias: "",
      añosMencion: "",
      menciones: "",
      confirmTemplate: false,
      newOptions: [],
    };
  },
  mounted() {
    this.getMenciones();
    this.getTemplates();
  },
  methods: {
    //metodo para agregar nuevas preguntas
    addInputs(index) {
      this.newEncuesta.secciones[index].preguntas.push({
        title: "",
        type: "",
        saved: false,
      });
    },
    addSection() {
      if (this.seccion == "") {
        createToast("Debe ingresar un título a la categoría.", {
          type: "danger",
        });
      } else {
        this.newEncuesta.secciones.push({
          titulo: this.seccion,
          preguntas: [],
        });
        this.seccion = "";
      }
    },
    eraseCat(secIndex) {
      this.newEncuesta.secciones.splice(secIndex, 1)
    },
    editSecc(seccion) {
      if (seccion.titulo == "") {
        createToast("Debe ingresar un título a la categoría.", {
          type: "danger",
        });
      } else {
        seccion.edit = false;
      }
    },
    //metodos get, post de axios
    //metodo para obtener los años de la mencion seleccionada
    async getYear(mencion_id) {
      await axios
        .get(API_URL + "/mencion/" + mencion_id + "/años")
        .then((response) => {
          this.añosMencion = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para obtener las plantillas
    async getTemplates() {
      await axios
        .get(API_URL + "/plantillas")
        .then((response) => {
          this.plantillas = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para obtener todas las materias de la mencion seleccionada y del año con sus profesores
    async getMateriasMencionSeccion(mencion_id, year, seccion) {
      this.materias = null;
      this.newEncuesta.materias = [];
      await axios
        .get(
          API_URL +
            "/mencion/" +
            mencion_id +
            "/año/" +
            year +
            "/seccion/" +
            seccion +
            "/materias"
        )
        .then((response) => {
          this.periodos_academicos = response.data.periodos_academicos;
          this.materias = response.data.materias;
          this.newEncuesta.materias = response.data.materias;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para obtener las secciones
    async getSecciones(mencion_id, year) {
      await axios
        .get(API_URL + "/mencion/" + mencion_id + "/año/" + year + "/secciones")
        .then((response) => {
          this.secciones = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para obtener informacion de las menciones
    async getMenciones() {
      await axios
        .get(API_URL + "/menciones")
        .then((response) => {
          this.menciones = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para guardar las preguntas con el boton guardar pregunta
    save(secIndex, index) {
      if (this.newEncuesta.secciones[secIndex].preguntas[index].title) {
        if (this.newEncuesta.secciones[secIndex].preguntas[index].options) {
          this.newEncuesta.secciones[secIndex].preguntas[index].saved = true;
        } else {
          this.errors.push("Error: La pregunta debe tener opciones");
        }
      } else {
        this.errors.push(
          "Error: Debe tener un título la pregunta " + (index + 1)
        );
      }
      this.generateErrorMessages();
    },
    //metodo para agregar opciones (obsoleto)
    addOption(index) {
      this.newOptions.push({
        preguntaid: index,
        nombre: "",
        valor: "",
      });
    },
    //metodo para obtener las categorias y preguntas cuando se selecciona una plantilla
    async getCategoriasYPreguntas() {
      this.categorias = [];
      console.log(this.plantilla);
      axios
        .get(API_URL + "/plantilla/" + this.plantilla)
        .then((response) => {
          this.categorias = response.data;
          console.log(this.categorias);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //metodo para aplicar la plantilla
    applyTemplate() {
      this.newEncuesta.secciones = [];
      this.categorias.forEach((categoria) => {
        categoria.preguntas.forEach((pregunta) => {
          pregunta.saved = true;
        });
      });
      this.newEncuesta.secciones = this.categorias;
    },
    //metodo para agregar plantilla de opciones
    addOptionsTemplate(secIndex, index) {
      this.newEncuesta.secciones[secIndex].preguntas[index]["options"] = [
        {
          preguntaid: index,
          nombre: "Deficiente",
          valor: "1",
        },
        {
          preguntaid: index,
          nombre: "Regular",
          valor: "2",
        },
        {
          preguntaid: index,
          nombre: "Bueno",
          valor: "3",
        },
        {
          preguntaid: index,
          nombre: "Muy bueno",
          valor: "4",
        },
        {
          preguntaid: index,
          nombre: "Excelente",
          valor: "5",
        },
      ];
    },
    //metodo para eliminar la pregunta con sus opciones si las tiene
    erase(secIndex, index) {
      this.newEncuesta.secciones[secIndex].preguntas.splice(index, 1);
    },
    //metodo para editar la pregunta
    edit(secIndex, index) {
      this.newEncuesta.secciones[secIndex].preguntas[index].saved = false;
    },
    //metodo para finalmente guardar la encuesta en la base de datos,
    //se buscan errores antes de enviar los datos a la bd
    async savePoll() {
      await this.checkErrors();
      if (this.errors.length > 0) {
        this.generateErrorMessages();
      } else {
        this.load = true;
        this.disabled = true;
        await axios
          .post(API_URL + "/encuesta/new", this.newEncuesta)
          .then((response) => {
            if (response.status == 200) {
              createToast("Cuestionario guardado con exito.", {
                type: "success",
              });
              this.$router.push({
                name: "Panel Administrador",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    //metodo que busca los errores en las preguntas
    checkErrors() {
      this.errors = [];
      if (this.newEncuesta.title == "") {
        this.errors.push("Error: El cuestionario debe tener un título");
      }
      if (this.newEncuesta.periodo_academico == "") {
        this.errors.push(
          "Error: El cuestionario debe ser asignado a un periodo academico"
        );
      }
      if (this.newEncuesta.fechaCreada == "") {
        this.errors.push(
          "Error: debe seleccionar una fecha inicial para el cuestionario."
        );
      }
      if (this.newEncuesta.fechaLimite == "") {
        this.errors.push(
          "Error: debe seleccionar una fecha final para el cuestionario."
        );
      }
      if (this.newEncuesta.secciones.length > 0) {
        for (
          let secIndex = 0;
          secIndex < this.newEncuesta.secciones.length;
          secIndex++
        ) {
          if (this.newEncuesta.secciones[secIndex].preguntas.length > 0) {
            let j = 1;
            this.newEncuesta.secciones[secIndex].preguntas.every((question) => {
              if (!question.saved) {
                this.errors.push(
                  "Error: debe guardar la pregunta Nro. " +
                    j +
                    " en la categoría Nro. " +
                    (secIndex + 1)
                );
              } else if (
                question.pressed == true &&
                question.hasOwnProperty("pressed")
              ) {
                return false;
              }
              j++;
            });
          } else {
            this.errors.push(
              "Error: debe haber minimo una pregunta por categoría en el cuestionario"
            );
          }
        }
      } else {
        this.errors.push(
          "Error: debe haber por lo menos una categoría por cuestionario."
        );
      }
    },
    //metodo que genera los mensajes toast exitosos
    generateMessages() {
      for (let i = 0; i < this.errors.length; i++) {
        createToast(this.errors[i], {
          type: "success",
          onClose: this.$router.push({
            name: "Panel Administrador",
          }),
        });
      }
      this.errors = [];
    },
    //metodo que genera los mensajes toast de error
    generateErrorMessages() {
      for (let i = 0; i < this.errors.length; i++) {
        createToast(this.errors[i], {
          type: "danger",
        });
      }
      this.errors = [];
    },
  },
};
</script>

<style scoped>
.dt {
  padding: 20px;
  border-radius: 15px;
  background: radial-gradient(#fff, #ebf9ff);
  margin-block: 15px;
}
</style>
