<template>
<div class="d-flex flex-column flex-shrink-0 bg-light fixed-top" :style="`top: ${scrollPos}`">
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center" style="top:0;">
        <li class="nav-item">
            <router-link to="/" class="nav-link py-3 border-bottom" :class="{ active: $route.path == '/panel-administrador'}">
                <font-awesome-icon icon="house" class="fa" />
                <div class="small">Inicio</div>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/cuestionarios" class="nav-link py-3 border-bottom" :class="{ active: $route.path == '/cuestionarios' || $route.path == '/crear-cuestionario' || $route.path == '/cuestionarios-disp'}">
                <font-awesome-icon icon="list-check" class="fa" />
                <div class="small" style="font-size: 13px;">Cuestionarios</div>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/estadisticas-menu" class="nav-link py-3 border-bottom" :class="{ active: $route.path == '/estadisticas-menu'}">
                <font-awesome-icon :icon="['far','chart-bar']" class="fa" />
                <div class="small">Estadísticas</div>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/configuracion" class="nav-link py-3 border-bottom" :class="{ active: $route.path == '/configuracion'}">
                <font-awesome-icon :icon="['fas','gear']" class="fa" />
                <div class="small" style="font-size: 12px;">Configuración</div>
            </router-link>
        </li>
    </ul>
    <div class="dropdown border-top">
        <a href="#" class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
            <font-awesome-icon icon="user-gear" class="rounded-circle" />
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
            <li><a class="dropdown-item" @click="logout" href="#">Cerrar Sesión</a></li>
        </ul>
    </div>
</div>
</template>

<script>
import $ from "jquery";


export default {   
    mounted() {
        
    },
    computed: {
        scrollPos() {
            return window.scrollY
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/');
        },
    },
}
</script>

<style scoped>

body {
    background-color: #eee
}

.absolute {
    position: absolute;
}

.dropup,
.dropdown {
    position: relative;
}

.d-flex {
    display: flex !important
}

.flex-column {
    flex-direction: column !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.bg-light {
    background-color: #f8f9fa !important;
}



.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-pills .nav-link {
    text-decoration: none;
    border-radius: 0.25rem;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.text-center {
    text-align: center !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-link {
    border-radius: 0px !important;
    transition: all 0.5s;
    width: 100px;
    display: flex;
    flex-direction: row;

    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
    color: inherit;
}

.nav-link:visited {
    color: #0D6EFD;
}

.nav-link.disabled {
    color: #6c757d;
}

.small {
    font-size: 12px
}

.nav-link:hover {
    background-color: #e8e8c1 !important
}

.active:hover {
    background-color: #075cdb !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.nav-link .fa {
    transition: all 1s;
    font-size: 20px
}

.fixed-top {    
    position: sticky !important;
    height: 100vh;
    width: 100px !important;
}

.nav-link:hover .fa {
    transform: rotate(360deg)
}
</style>

<style scoped src="../assets/bootstrap.min.css">

</style>