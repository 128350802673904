<template>
  <div v-if="!load" class="cols">
    <SidePanel />
    <div class="container">
      <h2>Estadísticas</h2>
      <h4>
        En este apartado podrá visualizar las estadisticas de los cuestionarios,
        ya sea de manera general o por profesor
      </h4>
      <div class="cards">
        <router-link class="card" to="/estadisticas">
          <font-awesome-icon
            style="font-size: 50px; margin-bottom: 10px"
            :icon="['fas', 'chart-line']"
            class="fa"
          />
          <div class="small">Estadísticas por profesor</div>
        </router-link>
        <router-link class="card" to="/estadisticas-generales">
          <font-awesome-icon
            style="font-size: 50px; margin-bottom: 10px"
            :icon="['fas', 'chart-area']"
            class="fa"
          />
          <div class="small">Estadistícas generales por cuestionarios</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../components/SidePanelAdmin.vue";
export default {
  components: {
    SidePanel,
  },
  data() {
    return {};
  },
};
</script>